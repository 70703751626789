import { DashboardLayout } from 'components/shared'
import { Suspense, lazy, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Arrowleft, Warning } from 'components/icons'
import { getLearnData } from 'redux/learn/learnSlice'
import { setOpenMenu } from 'redux/dashboard/dashboardSlice'
import { getTermsAndConditions } from 'redux/terms-and-conditions/termsAndConditionsSlice'
import { TourProvider } from '@reactour/tour'
import {
  steps,
  skillTrainerSteps,
  syllogismsTrainerSteps,
  calendarSteps,
} from './OnBoardingData'
import {
  clearViewedSteps,
  markTourSkipped,
  markTourStepViewed,
  setExploreTour,
  setTourVideoData,
  skipTourData,
} from 'redux/tour-steps/tourStepsSlice'
import { Redirect } from 'react-router-dom'
import { NoRecord } from 'components/no-record'
import { lazyRetry } from './lazyRetry'
import {
  isUcatPath,
  isValidSelector,
  isValidPromptSelector,
} from 'components/shared/helper'
import { setIsSettingOpen } from 'redux/learn/resourceSlice'

const BookChapter = lazy(() =>
  lazyRetry(() =>
    import('../../components/shared/chapter/module2/BookChapter'),
  ),
)

const Maintenance = lazy(() =>
  lazyRetry(() => import('../../pages/maintenance/Maintenance')),
)

const WorkExperience = lazy(() =>
  lazyRetry(() => import('../../pages/events/WorkExperience')),
)

const Presentation = lazy(() =>
  lazyRetry(() =>
    import('../../components/shared/chapter/module2/Presentation'),
  ),
)
const Dashboard = lazy(() =>
  lazyRetry(() => import('../../pages/dashboard/Dashboard')),
)
const MyJourney = lazy(() =>
  lazyRetry(() => import('../../pages/dashboard/MyJourney')),
)
const Faq = lazy(() => lazyRetry(() => import('../../pages/faq/Faq')))
const Community = lazy(() =>
  lazyRetry(() => import('../../pages/community/Community')),
)
const RegularTutoring = lazy(() =>
  lazyRetry(() => import('../../pages/tutoring/RegularTutoring')),
)

const ChatBot = lazy(() =>
  lazyRetry(() => import('../../pages/chatbot/ChatBot')),
)

const WalkthroughVideos = lazy(() =>
  lazyRetry(() => import('../../pages/walkthrough-videos/WalkthroughVideos')),
)
const Discussion = lazy(() =>
  lazyRetry(() => import('../../pages/community/Discussion')),
)
const DiscussionDescription = lazy(() =>
  lazyRetry(() => import('../../pages/community/DiscussionDescription')),
)
const Abstract = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/AbstractReasoning')),
)
const Meme = lazy(() => lazyRetry(() => import('../../pages/community/Meme')))
const MemeHistory = lazy(() =>
  lazyRetry(() => import('../../pages/community/MemeHistory')),
)
const Badges = lazy(() => lazyRetry(() => import('../../pages/profile/Badges')))
const MyGroup = lazy(() =>
  lazyRetry(() => import('../../pages/profile/MyGroup')),
)
const MyPurchases = lazy(() =>
  lazyRetry(() => import('../../pages/profile/MyPurchases')),
)
const PersonalInfo = lazy(() =>
  lazyRetry(() => import('../../pages/profile/PersonalInfo')),
)
const ChangePassword = lazy(() =>
  lazyRetry(() => import('../../pages/profile/ChangePassword')),
)
const Login = lazy(() => lazyRetry(() => import('../../pages/login/Login')))
const Learn = lazy(() => lazyRetry(() => import('../../pages/learn/Learn')))
const Orientation = lazy(() =>
  lazyRetry(() => import('../../pages/orientation/Orientation')),
)
const OrientaionPage = lazy(() =>
  lazyRetry(() => import('../../pages/orientation/OrientationPage')),
)

const Module2 = lazy(() =>
  lazyRetry(() => import('../../pages/learn/subcomponets/Module2')),
)
const Settings = lazy(() =>
  lazyRetry(() => import('../../pages/profile/Settings')),
)

const UnsubscribeSettings = lazy(() =>
  lazyRetry(() => import('../../pages/profile/UnsubscribeSettings')),
)
const Question = lazy(() =>
  lazyRetry(() => import('../../pages/community/Question')),
)
const Calendar = lazy(() =>
  lazyRetry(() => import('../../pages/calendar/Calendar')),
)
const QuestionHistory = lazy(() =>
  lazyRetry(() => import('../../pages/community/QuestionHistory')),
)
const QuestionHistoryDescription = lazy(() =>
  lazyRetry(() => import('../../pages/community/QuestionHistoryDescription')),
)
const MyFavorites = lazy(() =>
  lazyRetry(() => import('../../pages/my-favorites/MyFavorites')),
)
const Practice = lazy(() =>
  lazyRetry(() => import('../../pages/qbank/Practice')),
)
const SubtestMock = lazy(() =>
  lazyRetry(() => import('../../pages/qbank/SubtestMock')),
)
const FullExamMock = lazy(() =>
  lazyRetry(() => import('../../pages/qbank/FullExamMock')),
)
const InterviewGuide = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/InterviewGuide')),
)

const BondInterviewTrainer = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/BondInterviewTrainer')),
)

const ResourceModule = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/subcomponent/ResourceModule')),
)
const LeavingCertCalculator = lazy(() =>
  lazyRetry(() =>
    import('../../components/university-admission/LeavingCertCalculator'),
  ),
)
const Applicationguide = lazy(() =>
  lazyRetry(() =>
    import(
      '../../components/university-admission/application-guide/Applicationguide'
    ),
  ),
)
const Newguide = lazy(() =>
  lazyRetry(() =>
    import('../../components/university-admission/new-guide/Newguide'),
  ),
)
const ExtraExperience = lazy(() =>
  lazyRetry(() =>
    import(
      '../../components/university-admission/extra-experiences/ExtraExperience'
    ),
  ),
)
const SkillTrainers = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/SkillTrainers')),
)
const HpatSkillTrainers = lazy(() =>
  lazyRetry(() => import('../../pages/hpat-skill-trainers/HpatSkillTrainers')),
)
const SpeedReadingTrainer = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/SpeedReadingTrainer')),
)
const KeywordScanningTrainer = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/KeywordScanningTrainer')),
)
const VennDiagramTrainer = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/VennDiagramTrainer')),
)
const GraphTableTrainer = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/GraphTableTrainer')),
)
const ArithmeticTrainer = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/ArithmeticTrainer')),
)
const NumericKeypad = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/NumericKeypad')),
)
const Events = lazy(() => lazyRetry(() => import('../../pages/events/Events')))
const WeeklyClasses = lazy(() =>
  lazyRetry(() => import('../../pages/events/WeeklyClasses')),
)

const FortnightlySessions = lazy(() =>
  lazyRetry(() => import('../../pages/events/FortnightlySessions')),
)

const Syllogisms = lazy(() =>
  lazyRetry(() => import('../../pages/skill-trainers/Syllogisms')),
)
const Performance = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/Performance')),
)
const History = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/History')),
)
const PracticeReview = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/PracticeReview')),
)
const SubtestReview = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/SubtestReview')),
)
const ExamReview = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/ExamReview')),
)
const FreeFullUcatExam = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/FreeFullUcatExam')),
)
const ErrorLog = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/ErrorLog')),
)
const ErrorReview = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/ErrorReview')),
)
const HowToFix = lazy(() =>
  lazyRetry(() => import('../../pages/analytics/HowToFix')),
)
const Interview = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/Interview')),
)

const InterviewEventsTab = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/InterviewEventsTab')),
)
const InterviewEvents = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/InterviewEvents')),
)
const Casper = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/Casper')),
)

const InterviewTutoring = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/InterviewTutoring')),
)

const PsychometricTesting = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/PsychometricTesting')),
)
const MockInterviews = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/MockInterviews')),
)
const PersonalisedInterviews = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/PersonalisedInterviews')),
)
const UniversitySpecificQuestion = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/UniversitySpecificQuestion')),
)
const InterviewBank = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/InterviewBank')),
)

const InterviewVideoAnswers = lazy(() =>
  lazyRetry(() => import('../../pages/interviews/InterviewVideoAnswers')),
)

const ExamPortal = lazy(() =>
  lazyRetry(() => import('../../pages/exam-portal/ExamPortal')),
)
const HpatPortal = lazy(() =>
  lazyRetry(() => import('../../pages/hpat-portal/HpatPortal')),
)
const EurekaTrainer = lazy(() =>
  lazyRetry(() => import('../../pages/hpat-skill-trainers/EurekaTrainer')),
)
const EmotionsWordGame = lazy(() =>
  lazyRetry(() => import('../../pages/hpat-skill-trainers/EmotionsWordGame')),
)
const FullScreenCalculator = lazy(() =>
  lazyRetry(() => import('../../pages/calculator/FullScreenCalculator')),
)

const GlobalSearch = lazy(() =>
  lazyRetry(() => import('components/shared/layouts/GlobalSearch')),
)
const GotoQuestion = lazy(() =>
  lazyRetry(() => import('../../pages/qbank/GotoQuestion')),
)
const GoToAttempt = lazy(() =>
  lazyRetry(() => import('../../pages/qbank/GoToAttempt')),
)
const LoginFromLink = lazy(() =>
  lazyRetry(() => import('../../pages/login/LoginFromLink')),
)

const afterOpen = () => {
  document.body.style.margin = 0
  document.body.style.height = '100%'
  document.body.style.overflow = 'hidden'
}
const beforeClose = () => {
  document.body.style.margin = 0
  document.body.style.height = 'auto'
  document.body.style.overflow = 'auto'
}
const AppRouter = () => {
  const dispatch = useDispatch()
  const {
    tourStepsData,
    tourPageName,
    openMenu,
    olpPath,
    isSettingOpen,
    exploreTour,
    currentPrompt,
  } = useSelector((state) => ({
    tourStepsData: state.tourSteps.tourStepsData,
    tourPageName: state.tourSteps.tourPageName,
    openMenu: state.dashboard.openMenu,
    olpPath: state.personalInfo.olpPath,
    isSettingOpen: state.resource.isSettingOpen,
    exploreTour: state.tourSteps.exploreTour,
    currentPrompt: state.dashboard.promptsData.currentPrompt,
  }))

  const isCustomTour =
    (isValidPromptSelector(currentPrompt?.prompt_data?.selector) &&
      window.location.pathname === '/') ||
    exploreTour
  const reactourStyles = {
    popover: (base) => ({
      ...base,
      borderRadius: 6,
      padding: isCustomTour ? 0 : 24,
      maxWidth: '534px',
    }),

    maskArea: (base) => ({
      ...base,
      rx: 6,
      overflow: 'hidden',
    }),
    prevButton: () => ({
      display: 'none',
    }),
    maskWrapper: (base) => ({
      ...base,
    }),
    badge: (base) => ({ ...base, display: 'none' }),
    controls: (base) => ({
      ...base,
      marginTop: 24,
      justifyContent: 'flex-start',
    }),
    close: (base) => ({ ...base, display: 'none' }),
    dot: (base) => ({
      ...base,
      display: 'none',
    }),
  }
  const onClickMask = ({ setCurrentStep, currentStep, setIsOpen }) => {
    if (currentStep === steps.length - 1) {
      setIsOpen(false)
      exploreTour && dispatch(setExploreTour(false))
    }
    setCurrentStep((s) => (s === steps.length - 1 ? 0 : s))
  }

  const prevButton = ({
    currentStep,
    stepsLength,
    setIsOpen,
    setCurrentStep,
  }) => {
    const last = currentStep === stepsLength - 1
    return isCustomTour ? (
      ''
    ) : (
      <button
        onClick={() => {
          tourStepsData.data &&
            tourStepsData.data.tour &&
            tourStepsData.data.tour.steps &&
            tourStepsData.data.tour.steps.length > 0 &&
            dispatch(
              markTourStepViewed({
                id: tourStepsData?.data?.tour?.steps?.filter(
                  (step) =>
                    isValidSelector(step.selector) &&
                    document.querySelectorAll(step.selector).length,
                )[currentStep]?.id,
              }),
            )

          if (last) {
            const lastVideoTourStep = tourStepsData?.data?.tour?.steps?.find(
              (tour) => tour?.selector === '.tour-video-selector',
            )
            lastVideoTourStep && dispatch(setTourVideoData(lastVideoTourStep))

            if (openMenu) {
              dispatch(setOpenMenu(false))
            }
            if (isSettingOpen) {
              dispatch(setIsSettingOpen(false))
            }
            setIsOpen(false)
            dispatch(
              clearViewedSteps(
                tourStepsData?.data &&
                  tourStepsData?.data?.tour &&
                  tourStepsData?.data?.tour?.steps?.filter(
                    (step) =>
                      isValidSelector(step.selector) &&
                      document.querySelectorAll(step.selector).length,
                  ),
              ),
            )
            setCurrentStep(0)
          } else {
            setCurrentStep((s) =>
              s === tourStepsData.data?.tour?.length - 1 ? 0 : s + 1,
            )
          }
        }}
        className="flex items-center px-6 py-4 bg-red-500 rounded-md text-xs text-white tracking-widest font-semibold ring-0 focus:ring-0"
      >
        {last ? 'GOT IT' : 'NEXT'}
        <Arrowleft
          className={`${last && 'hidden'} ml-12 transform rotate-180`}
        />
      </button>
    )
  }

  const nextButton = ({ currentStep, stepsLength, setIsOpen }) => {
    const last = currentStep === stepsLength - 1
    return (
      <button
        className={
          last
            ? 'hidden'
            : 'ml-6 text-xs tracking-widest text-red-500 font-semibold px-2 py-1 uppercase'
        }
        onClick={() => {
          if (!last) {
            setIsOpen(false)
          }
          if (openMenu) {
            dispatch(setOpenMenu(false))
          }
          if (window.location.pathname.search(/attempt/) === -1) {
            dispatch(setExploreTour(true))
          }
          dispatch(
            markTourSkipped({
              page: tourPageName ? tourPageName : 'home',
              onSuccess: () => {
                dispatch(skipTourData())
              },
            }),
          )
        }}
      >
        I'll explore on my own
      </button>
    )
  }

  const localTheme = localStorage?.theme
  useEffect(() => {
    // dispatch(getSettingInfo())
    if (
      localTheme === 'Dark' ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.getElementById('favicon').href = '/favicon-light.png'
      document.documentElement.classList.add('dark')
      localStorage.theme = 'Dark'
    } else {
      document.getElementById('favicon').href = '/favicon.png'
      document.documentElement.classList.remove('dark')
      localStorage.theme = 'Light'
    }
  }, [dispatch, localTheme])

  const userToken = localStorage?.getItem('bearer_token')
  useEffect(() => {
    if (userToken && olpPath) {
      dispatch(getLearnData())
    }
  }, [dispatch, userToken, olpPath])
  const isUserAgreed = JSON.parse(sessionStorage.getItem('isAgreed'))
  useEffect(() => {
    if (!isUserAgreed && userToken && olpPath) {
      dispatch(getTermsAndConditions())
    }
  }, [dispatch, userToken, olpPath, isUserAgreed])
  return (
    <BrowserRouter>
      <TourProvider
        scrollSmooth
        className="dark:bg-dark-700 dark:text-white"
        padding={{ mask: 4 }}
        inViewThreshold={-1}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        styles={reactourStyles}
        prevButton={prevButton}
        nextButton={nextButton}
        disableInteraction={true}
        onClickMask={onClickMask}
      >
        <DashboardLayout>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route path="/login" component={Login} exact />
              <PrivateRoute path="/" exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  inViewThreshold={-1}
                  onClickMask={onClickMask}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction={true}
                >
                  <Dashboard />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute path="/home/my-journey" exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 6 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <MyJourney />
                </TourProvider>
              </PrivateRoute>
              <Route
                path="/olp-access/:encodedToken"
                component={LoginFromLink}
                exact
              />
              <PrivateRoute path="/faq" component={Faq} exact />
              <PrivateRoute path="/community" component={Community} exact />
              <PrivateRoute path="/community/discussion" exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction={true}
                >
                  <Discussion />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path="/community/question"
                component={Question}
                exact
              />
              <PrivateRoute
                path="/community/question/history"
                component={QuestionHistory}
                exact
              />
              <PrivateRoute
                path="/community/question/history/description/:historyId"
                component={QuestionHistoryDescription}
                exact
              />
              <PrivateRoute path="/community/meme" component={Meme} exact />
              <PrivateRoute
                path="/community/meme/history"
                component={MemeHistory}
                exact
              />
              <PrivateRoute
                path="/community/discussion/:discussionId"
                component={DiscussionDescription}
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <DiscussionDescription />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path="/profile/my-purchases"
                component={MyPurchases}
                exact
              />
              <PrivateRoute
                path="/profile/my-group"
                component={MyGroup}
                exact
              />
              <PrivateRoute path="/profile/badges" component={Badges} exact />
              <PrivateRoute
                path="/profile/personal-info"
                component={PersonalInfo}
                exact
              />
              {/* <Route path="/bookmarks/bookmarks" component={Bookmarks} exact /> */}
              <PrivateRoute
                path="/profile/change-password"
                component={ChangePassword}
                exact
              />
              {/* <Route path="/profile/settings" component={MyProfile} exact /> */}
              <PrivateRoute path="/orientation" exact>
                <Orientation />
              </PrivateRoute>
              <PrivateRoute path="/orientation/:slug" exact>
                <OrientaionPage />
              </PrivateRoute>
              <PrivateRoute
                restrictUser={['hpat']}
                path="/interviews"
                component={Interview}
                exact
              />
              <PrivateRoute
                path="/interviews/resources"
                component={Interview}
                exact
              />
              <PrivateRoute
                path="/interviews/live-events"
                component={InterviewEventsTab}
                exact
              />
              <PrivateRoute
                path="/interviews/events"
                component={InterviewEvents}
                exact
              />
              <PrivateRoute
                path="/interviews/interview-tutoring"
                exact
                component={InterviewTutoring}
              />

              <PrivateRoute
                path="/interviews/casper/booking"
                component={Casper}
                exact
              />
              <PrivateRoute
                path="/interviews/casper/recording"
                component={Casper}
                exact
              />

              <PrivateRoute
                path="/interviews/psychometric-bookings"
                component={PsychometricTesting}
                exact
              />
              <PrivateRoute
                path="/interviews/mock-interviews"
                component={MockInterviews}
                exact
              />
              <PrivateRoute
                restrictUser={['hpat']}
                path="/interviews/personalised-interviews"
                component={PersonalisedInterviews}
                exact
              />
              <PrivateRoute
                path="/interviews/interview-bank"
                restrictUser={['hpat']}
                exact
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction={true}
                >
                  <InterviewBank />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path="/interviews/interview-bank/video-answers"
                exact
                component={InterviewVideoAnswers}
                restrictUser={['hpat']}
              />
              <PrivateRoute
                path="/interviews/university-specific-questions"
                restrictUser={['hpat']}
                component={UniversitySpecificQuestion}
                exact
              />
              <PrivateRoute
                path="/interviews/interview-guide"
                component={InterviewGuide}
                restrictUser={['hpat']}
                exact
              />
              <PrivateRoute
                path="/interviews/bond-interview-trainer"
                component={BondInterviewTrainer}
                restrictUser={['hpat']}
                exact
              />
              <PrivateRoute
                path="/university-admission/:subMenu/:moduleArea/:resourceType/:resourceSlug/:chapterIndex"
                exact
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <BookChapter isUniversity={true} />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path="/profile/settings"
                component={Settings}
                exact
              />
              <PrivateRoute
                path="/bookmarks/bookmarks"
                component={MyFavorites}
                exact
              />
              <PrivateRoute
                path="/bookmarks/notes"
                component={MyFavorites}
                exact
              />
              <PrivateRoute
                path="/bookmarks/my-tutoring-questions"
                component={MyFavorites}
                exact
              />
              <PrivateRoute
                path="/bookmarks/my-online-queries"
                component={MyFavorites}
                exact
              />
              <PrivateRoute
                path="/bookmarks/my-discussion"
                component={MyFavorites}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/practice/:PRACTICETYPE`}
                exact
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <Practice />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/qbank/practice/:PRACTICETYPE/:subTestId`}
                component={Practice}
                exact
              />
              <PrivateRoute
                path="/go-to-question/:qbankId"
                component={GotoQuestion}
                exact
              />
              <PrivateRoute
                path="/university-admission/leaving-cert-point-calculator"
                restrictUser={['ucat']}
                component={LeavingCertCalculator}
              />
              <PrivateRoute
                path="/go-to-attempt/:attemptId"
                component={GoToAttempt}
                exact
              />
              <PrivateRoute
                path="/university-admission/written-application-guide"
                component={Applicationguide}
                exact
              />
              <PrivateRoute path="/university-admission/new-guide" exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction={true}
                >
                  <Newguide />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path="/university-admission/extra-experiences"
                component={ExtraExperience}
                exact
              />
              <PrivateRoute
                path="/home/my-journey/extra-experiences"
                component={ExtraExperience}
                exact
              />
              <PrivateRoute path="/search" exact component={GlobalSearch} />
              {/* <Route path="/profile/settings" component={MyProfile} exact /> */}
              <PrivateRoute path={`${isUcatPath}/learn`} exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction={true}
                >
                  <Learn />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute path={`${isUcatPath}/learn/module/:moduleId`} exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction={true}
                >
                  <Module2 />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/:interviewArea/module/:moduleId`}
                component={ResourceModule}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/module/:moduleArea/:moduleId/:resourceType/:bookId/:resourceSlug/:chapterIndex`}
                exact
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <BookChapter />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/module/:moduleArea/:moduleId/:resourceType/:subModuleId/:resourceSlug`}
                component={Presentation}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/practice/:PRACTICETYPE`}
                exact
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <Practice />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/qbank/practice/:PRACTICETYPE/:subTestId`}
                component={Practice}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/full-exam-mocks`}
                component={FullExamMock}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers/speed-reading-trainer`}
                exact
              >
                <TourProvider
                  steps={skillTrainerSteps || []}
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <SpeedReadingTrainer />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers/keyword-scanning-trainer`}
                component={KeywordScanningTrainer}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers/venn-diagram-trainer`}
                component={VennDiagramTrainer}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers/graph-table-trainer`}
                component={GraphTableTrainer}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers/arithmetic-trainer`}
                component={ArithmeticTrainer}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers/numeric-keypad-trainer`}
                component={NumericKeypad}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers/syllogisms-trainer`}
                exact
              >
                <TourProvider
                  steps={syllogismsTrainerSteps || []}
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 6 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <Syllogisms />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute path={`${isUcatPath}/calendar`} exact>
                <TourProvider
                  steps={null}
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 6 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction
                >
                  <Calendar />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/chatbot`}
                component={ChatBot}
                exact
              />
              <PrivateRoute
                path="/tutoring"
                exact
                component={RegularTutoring}
              />

              <PrivateRoute
                path="/walkthrough-videos"
                exact
                component={WalkthroughVideos}
                restrictUser={['hpat']}
              />
              <PrivateRoute
                path="/university-admission/work-experience/booking"
                component={WorkExperience}
                exact
              />
              <PrivateRoute
                path="/university-admission/work-experience/recording"
                component={WorkExperience}
                exact
              />
              <PrivateRoute path={`${isUcatPath}/events`} exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 6 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <Events />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path="/teaching/workshop/bookings"
                exact
                component={Events}
              />
              <PrivateRoute
                path="/teaching/workshop/resources"
                exact
                component={Events}
              />
              <PrivateRoute
                path="/teaching/classes/bookings"
                exact
                component={WeeklyClasses}
              />
              <PrivateRoute
                path="/teaching/drop-in-q&as/bookings"
                exact
                component={FortnightlySessions}
              />
              <PrivateRoute
                path="/teaching/drop-in-q&as/recordings"
                exact
                component={FortnightlySessions}
              />

              <PrivateRoute
                path="/teaching/classes/weekly-classes"
                exact
                component={WeeklyClasses}
              />
              <PrivateRoute path={`${isUcatPath}/analytics/performance`} exact>
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction={false}
                >
                  <Performance />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/qbank/history`}
                exact
                component={History}
              />
              <PrivateRoute
                restrictUser={['hpat']}
                path={`${isUcatPath}/qbank/history/subtest`}
                exact
                component={History}
              />
              <PrivateRoute
                restrictUser={['ucat']}
                path="/qbank/history/section"
                exact
                component={History}
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/history/full-exam`}
                exact
                component={History}
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/history/practice-review/:practiceId`}
                exact
                component={PracticeReview}
              />
              <PrivateRoute
                restrictUser={['hpat']}
                path={`${isUcatPath}/qbank/history/subtest-review/:subtestReviewId`}
                exact
                component={SubtestReview}
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/history/section-review/:subtestReviewId`}
                exact
                component={SubtestReview}
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/history/exam-review/:examId`}
                exact
                component={ExamReview}
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/history/exam-review/:examType/:sectionId/:reviewDataId`}
                exact
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 0 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction
                >
                  <FreeFullUcatExam isHpat={olpPath === 'hpat'} />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/analytics/error-log`}
                exact
                component={ErrorLog}
              />
              <PrivateRoute
                path={`${isUcatPath}/analytics/error-log/decision-making`}
                exact
                component={ErrorLog}
              />
              <PrivateRoute
                path={`${isUcatPath}/analytics/error-log/quantitative-reasoning`}
                exact
                component={ErrorLog}
              />
              <PrivateRoute
                path={`${isUcatPath}/analytics/error-log/situational-judgement`}
                exact
                component={ErrorLog}
              />
              <PrivateRoute
                path="/attempt/:attemptId"
                exact
                restrictUser={['hpat']}
              >
                <TourProvider
                  steps={
                    (tourStepsData.data &&
                      tourStepsData.data.tour &&
                      tourStepsData.data.tour.steps) ||
                    []
                  }
                  onClickMask={onClickMask}
                  scrollSmooth
                  inViewThreshold={-1}
                  className=""
                  padding={{ mask: 0 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  disableInteraction
                >
                  <ExamPortal />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/analytics/error-log/review-errors/:sectionId`}
                exact
                component={ErrorReview}
              />
              <PrivateRoute
                path={`${isUcatPath}/analytics/error-log/how-to-fix/:id`}
                exact
                component={HowToFix}
              />
              <PrivateRoute
                path={`${isUcatPath}/skill-trainers`}
                component={
                  olpPath === 'hpat' ? HpatSkillTrainers : SkillTrainers
                }
                exact
              />
              <Route path="/ucat-calculator" exact>
                <FullScreenCalculator />
              </Route>
              <Route path="/unsubscribe/:unsubscribeToken" exact>
                <UnsubscribeSettings />
              </Route>
              <PrivateRoute
                path="/qbank/exam/:attemptId"
                exact
                restrictUser={['ucat']}
                component={HpatPortal}
              />
              <PrivateRoute
                path="/skill-trainers/emotions-word-game-trainer"
                component={EmotionsWordGame}
                restrictUser={['ucat']}
                exact
              />
              <PrivateRoute
                path="/skill-trainers/eureka-trainer"
                component={EurekaTrainer}
                restrictUser={['ucat']}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/subtest-mocks`}
                restrictUser={['hpat']}
                component={SubtestMock}
                exact
              />
              <PrivateRoute
                path="/qbank/section-mocks"
                component={SubtestMock}
                restrictUser={['ucat']}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/qbank/subtest-mocks/:subtestId`}
                restrictUser={['hpat']}
                component={SubtestMock}
                exact
              />
              <PrivateRoute
                path="/qbank/section-mocks/:subtestId"
                restrictUser={['ucat']}
                component={SubtestMock}
                exact
              />
              <PrivateRoute
                path={`${isUcatPath}/:moduleArea/:resourceSlug/:chapterIndex`}
                exact
              >
                <TourProvider
                  steps={tourStepsData?.data?.tour?.steps || []}
                  onClickMask={onClickMask}
                  inViewThreshold={-1}
                  scrollSmooth
                  className="dark:bg-dark-700 dark:text-white"
                  padding={{ mask: 4 }}
                  afterOpen={afterOpen}
                  beforeClose={beforeClose}
                  styles={reactourStyles}
                  prevButton={prevButton}
                  nextButton={nextButton}
                >
                  <BookChapter />
                </TourProvider>
              </PrivateRoute>
              <PrivateRoute
                path={`${isUcatPath}/:moduleArea/:resourceSlug`}
                exact
              >
                <BookChapter />
              </PrivateRoute>
              <PrivateRoute path="/404">
                <NoRecord
                  icon={<Warning className="h-5 w-5 text-gray-500" />}
                  title="Page not found"
                />
              </PrivateRoute>
              <PrivateRoute path="*">
                <NoRecord
                  icon={<Warning className="h-5 w-5 text-gray-500" />}
                  title="Page not found"
                />
              </PrivateRoute>
            </Switch>
          </Suspense>
        </DashboardLayout>
      </TourProvider>
    </BrowserRouter>
  )
}

const normalizeURLPath = (path) => {
  return path.replace(/\/{2,}/g, '/')
}

function PrivateRoute({ children, component, restrictUser, ...rest }) {
  const userToken = localStorage.getItem('bearer_token')
  const cohort = process.env.REACT_APP_USER_COHORT
  const { userData, olpPath } = useSelector((state) => ({
    userData: state.personalInfo.userData,
    olpPath: state.personalInfo.olpPath,
  }))

  const accessArea = cohort
    ? (userData?.cohort && userData?.cohort === cohort) || false
    : true

  const normalizedPath = normalizeURLPath(window.location.pathname)
  if (userToken && accessArea) {
    if (restrictUser && restrictUser?.includes(olpPath)) {
      return (
        <Redirect
          to={{
            pathname: '/404',
          }}
        />
      )
    }
    if (
      !userData?.can_bypass_maintenance &&
      parseInt(process?.env?.REACT_APP_DOWN_FOR_MAINTENANCE) === 1
    ) {
      return <Maintenance />
    }
    if (window.location.pathname !== normalizedPath) {
      return <Redirect to={normalizedPath} />
    }
    if (component) {
      return <Route {...rest} component={component} />
    } else {
      return <Route {...rest} render={() => children} />
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    )
  }
}

export default AppRouter
