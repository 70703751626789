import React, { useEffect, useRef, useState } from 'react'
import closeIcon from '../../styles/assets/new-icons.png'
const Draggable = ({
  children,
  onHide,
  show,
  title,
  resize,
  draggableIcon,
  minDimensions,
  headerHeight,
  closeOnOutsideClick,
  isOutsideExam,
  disabled,
  closeOnOutsideHandler,
  positionStart,
  defaultPosition,
  subTitle,
  onlyRightSidedResize,
  spanIcon,
  divXWidth,
  divYHeight,
  loadCalculator,
  ...props
}) => {
  let Xpos = 0,
    Ypos = 0,
    mouseXpos = 0,
    mouseYpos = 0
  const dragRef = useRef()
  const elementDrag = (e) => {
    e.preventDefault()
    const innerViewportWidth = divXWidth ? divXWidth : window.innerWidth
    const innerViewportHeight = divYHeight ? divYHeight : window.innerHeight

    /*
        compensation width for borders, padding and the width of vertical scrollbar, as well the height of bottom most blue bar for next question
        so that there is no horizontal scrollbar on dragging the scratch pad to the extreme right
        */
    const compensationDimension = 0
    // calculate the new cursor position:
    Xpos = mouseXpos - e.clientX
    Ypos = mouseYpos - e.clientY
    mouseXpos = e.clientX
    mouseYpos = e.clientY

    const scratchPadWidth = dragRef?.current?.offsetWidth
    const scratchPadHeight = dragRef?.current?.offsetHeight

    // set the element's new position:
    if (
      dragRef?.current?.offsetLeft &&
      dragRef.current.offsetLeft - Xpos > -1 &&
      dragRef.current.offsetLeft - Xpos < innerViewportWidth - scratchPadWidth
    ) {
      dragRef.current.style.left = dragRef.current.offsetLeft - Xpos + 'px'
    }

    if (
      dragRef?.current?.offsetTop - Ypos > -compensationDimension &&
      dragRef?.current?.offsetTop - Ypos <
        innerViewportHeight -
          (scratchPadHeight +
            (resize ? compensationDimension : compensationDimension + 10))
    ) {
      dragRef.current.style.top = dragRef.current?.offsetTop - Ypos + 'px'
    }
  }

  const elementDragTouch = (e) => {
    // e.preventDefault()
    const innerViewportWidth = window.innerWidth
    const innerViewportHeight = window.innerHeight

    /*
        compensation width for borders, padding and the width of vertical scrollbar, as well the height of bottom most blue bar for next question
        so that there is no horizontal scrollbar on dragging the scratch pad to the extreme right
        */
    const compensationDimension = 0
    // calculate the new cursor position:
    Xpos = mouseXpos - e.touches[0].clientX
    Ypos = mouseYpos - e.touches[0].clientY
    mouseXpos = e.touches[0].clientX
    mouseYpos = e.touches[0].clientY

    const scratchPadWidth = dragRef?.current?.offsetWidth
    const scratchPadHeight = dragRef?.current?.offsetHeight

    // set the element's new position:
    if (
      dragRef?.current?.offsetLeft &&
      dragRef.current.offsetLeft - Xpos > -1 &&
      dragRef.current.offsetLeft - Xpos < innerViewportWidth - scratchPadWidth
    ) {
      dragRef.current.style.left = dragRef.current.offsetLeft - Xpos + 'px'
    }

    if (
      dragRef?.current?.offsetTop - Ypos > -compensationDimension &&
      dragRef?.current?.offsetTop - Ypos <
        innerViewportHeight -
          (scratchPadHeight +
            (resize ? compensationDimension : compensationDimension + 10))
    ) {
      dragRef.current.style.top = dragRef?.current?.offsetTop - Ypos + 'px'
    }
  }

  const closeDragElementTouch = () => {
    document.ontouchend = null
    document.ontouchmove = null
  }

  const closeDragElement = () => {
    document.onmouseup = null
    document.onmousemove = null
  }

  const touchMouseDown = (e) => {
    // get the mouse cursor position at startup:
    mouseXpos = e.touches[0].clientX
    mouseYpos = e.touches[0].clientY
    document.ontouchend = closeDragElementTouch
    // call a function whenever the cursor moves:
    document.ontouchmove = elementDragTouch
  }

  const dragMouseDown = (e) => {
    e.preventDefault()
    // get the mouse cursor position at startup:
    mouseXpos = e.clientX
    mouseYpos = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }

  // Resize component
  const [customStyle, setCustomStyle] = useState({
    left: '',
    width: minDimensions.width,
    height: minDimensions.height,
  })
  useEffect(() => {
    if (loadCalculator) {
      setCustomStyle((prevStyle) => ({
        ...prevStyle,
        height: minDimensions.height,
      }))
    }
  }, [loadCalculator, minDimensions.height])
  const startResize = (e) => {
    const isLeft = e.target.classList.contains('draggable-resize-arrow-left')
    document.onmousemove = (e) => resizeFrame(e, isLeft)
    document.onmouseup = stopResize
  }
  const resizeFrame = (e, isLeft) => {
    const element = dragRef.current
    const deltaWidth = e.clientX - element.offsetLeft
    const deltaHeight = e.clientY - element.offsetTop - headerHeight
    if (isLeft) {
      setCustomStyle((prev) => ({
        left:
          prev.width - deltaWidth < minDimensions.width ? prev.left : e.clientX,
        width: Math.max(minDimensions.width, prev.width - deltaWidth),
        height: Math.max(minDimensions.height, deltaHeight),
      }))
    } else {
      setCustomStyle((prev) => ({
        ...prev,
        width: Math.max(minDimensions.width, deltaWidth),
        height: Math.max(minDimensions.height, deltaHeight),
      }))
    }
  }
  const stopResize = (e) => {
    document.onmousemove = null
    document.onmouseup = null
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dragRef.current && !dragRef.current.contains(event.target)) {
        closeOnOutsideHandler && closeOnOutsideHandler()
      }
    }
    if (closeOnOutsideClick && show)
      document.addEventListener('mousedown', handleClickOutside, true)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [closeOnOutsideClick, show])
  useEffect(() => {
    if (!show) {
      if (dragRef?.current?.style?.left) {
        dragRef.current.style.left = ''
        dragRef.current.style.top = ''
      }
    }
  }, [show])
  return (
    <>
      <div
        ref={dragRef}
        style={{
          ...customStyle,
          touchAction: 'none',
          position: isOutsideExam ? 'fixed' : '',
          background: '#0d6daa',
        }}
        className={`z-50 draggable ${
          positionStart
            ? ''
            : defaultPosition
            ? defaultPosition
            : 'draggable-position-class'
        } 
         ${show ? 'd-block' : 'd-none'}`}
      >
        <div
          className={`draggable-header ${disabled ? 'disabled' : ''}`}
          onMouseDown={dragMouseDown}
          onTouchStart={touchMouseDown}
        >
          <div className="draggable-header-body">
            <div className="draggable-header-title flex items-center">
              {spanIcon && spanIcon}
              {draggableIcon && (
                <img
                  src={draggableIcon}
                  alt={title}
                  className={`draggable-icon ${
                    !resize && 'draggable-sprite-icon'
                  } `}
                />
              )}
              <label>{title}</label>
              {subTitle && (
                <span className="ml-1 mt-0.5 text-sm">{subTitle}</span>
              )}
            </div>
            <div
              className={`close-btn calculator-close ${
                (title === 'Calculator' || title === 'Navigator') &&
                'calculator-close'
              }`}
              onClick={onHide}
            >
              {closeBtn}
            </div>
          </div>
        </div>
        {children}
        {resize && (
          <div className="draggable-resize-body">
            {!onlyRightSidedResize && (
              <div
                className="draggable-resize-arrow draggable-resize-arrow-left"
                onMouseDown={startResize}
              ></div>
            )}
            <div
              className="draggable-resize-arrow draggable-resize-arrow-right"
              onMouseDown={startResize}
            ></div>
          </div>
        )}
      </div>
    </>
  )
}

export default Draggable

const closeBtn = (
  <img
    className="max-w-full align-middle close-button"
    alt="Question number indicator"
    style={{
      backgroundImage: `url(${closeIcon})`,
    }}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPAQMAAAAxlBYoAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAAxJREFUeNpjYCAfAAAAPAAB0S89/gAAAABJRU5ErkJggg=="
  />
)
