import { Lock, About } from 'components/icons'
import { Link } from 'react-router-dom'

const FreeTrail = ({
  flexRow,
  oneLine,
  text,
  subText,
  title,
  linkText,
  disableAboutIcon,
  afterLinkText,
  redirectToOtherWebsite,
}) => {
  if (oneLine) {
    return (
      <div className="flex items-center justify-start">
        {!disableAboutIcon && <About className="dark:text-white" />}
        <p className="ml-2 text-base font-normal text-gray-900 dark:text-white">
          {text ? text : '20 questions available. To unlock'}{' '}
          {redirectToOtherWebsite ? (
            redirectToOtherWebsite
          ) : (
            <Link to="/profile/my-purchases" className="text-blue-500 ">
              {linkText ? linkText : 'Purchase a package'}
            </Link>
          )}
          .
        </p>
      </div>
    )
  } else {
    return (
      <div
        className={`w-full flex items-center ${
          flexRow ? 'justify-start' : 'justify-center'
        }`}
      >
        <div
          className={`flex ${
            flexRow ? 'flex-row justify-start' : 'flex-col justify-center'
          } items-center`}
        >
          <div
            style={{ minWidth: '64px', minHeight: '64px' }}
            className="h-16 w-16 bg-gray-50 dark:bg-dark-400 flex items-center justify-center rounded-full"
          >
            <Lock className="h-6 w-6 text-gray-500" />
          </div>
          {!flexRow && (
            <h3 className="text-gray-900 dark:text-white text-xl font-semibold mt-6">
              {title ? title : 'This area is unavailable'}
            </h3>
          )}
          <p
            className={`text-base font-normal text-gray-900 dark:text-white ${
              flexRow ? 'mt-0 ml-4' : 'mt-4'
            }`}
          >
            {subText ? subText : 'To unlock'}{' '}
            {redirectToOtherWebsite ? (
              redirectToOtherWebsite
            ) : (
              <Link to="/profile/my-purchases" className="text-blue-500 ">
                {linkText ? linkText : 'Purchase a package'}
              </Link>
            )}
            {afterLinkText ? ' ' + afterLinkText : ''}.
          </p>
        </div>
      </div>
    )
  }
}

export default FreeTrail
