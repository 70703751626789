import { Link, useLocation } from 'react-router-dom'
import { Logo } from './Logos'
import '../../../styles/sidebar.scss'
import {
  Archieve,
  Calendar,
  CalendarAdd,
  Chat,
  ChatIcon,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Community,
  Faq,
  Graph,
  Home,
  Learn,
  Math,
  University,
  User,
} from 'components/icons'
import { Disclosure, Transition } from '@headlessui/react'
import { SmallLogo } from './Logos'
import { useSelector, useDispatch } from 'react-redux'
import {
  openSidebar,
  toggleNavigation,
  toggleReviewSidebar,
  toggleSidebar,
} from 'redux/dashboard/dashboardSlice'
import { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'components/form'
import Tutor from 'components/icons/Tutor'
import { useHistory } from 'react-router-dom'
import { isUcatPath } from '../helper'

// this function is use for show active tab in the sidebar
const isActive = (locationPath, menuItemPath, urlSubSets, outsidePath) => {
  if (locationPath.includes(outsidePath)) {
    return false
  }
  return (
    locationPath
      .split('/')
      .filter(
        (val) =>
          menuItemPath.split('/').slice(0, urlSubSets).indexOf(val) !== -1,
      )
      .join('/') === menuItemPath.split('/').slice(0, urlSubSets).join('/')
  )
}
const DropdownMenu = ({ item, sidebarExpanded }) => {
  const location = useLocation()
  const [openDropdown, setOpenDropdown] = useState(false)
  const dispatch = useDispatch()

  const isActiveSubMenu = (menuItem) =>
    menuItem?.includesUrl?.length > 0
      ? menuItem?.includesUrl?.some((element) =>
          location?.pathname?.includes(element),
        )
      : isActive(
          location.pathname,
          menuItem.path,
          item?.checkPathLength || 4,
          item?.outsidePath,
        )

  useEffect(() => {
    if (item?.customActive) {
      setOpenDropdown(
        item?.items?.filter((menuItem) =>
          menuItem?.includesUrl?.some((element) =>
            location?.pathname?.includes(element),
          ),
        ).length > 0
          ? true
          : false,
      )
    } else {
      setOpenDropdown(
        item.items &&
          item.items.filter(
            (menuItem) =>
              menuItem.path.split('/').slice(0, 3).join('/') ===
              location.pathname.split('/').slice(0, 3).join('/'),
          ).length > 0
          ? true
          : false,
      )
    }
  }, [location.pathname])

  if (!sidebarExpanded) {
    return (
      <div className="menu-item flex relative group cursor-pointer">
        <div
          className={`flex items-center ${
            item?.items.filter((menuItem) => isActiveSubMenu(menuItem)).length >
            0
              ? 'fill-current text-red-500'
              : ''
          }`}
        >
          {item.icon}
          {sidebarExpanded && (
            <div
              className={`text-sm text-gray-700 dark:text-dark-100`}
              onClick={() => dispatch(toggleNavigation())}
            >
              {item.title}
            </div>
          )}
        </div>
        <div className="fixed w-64 bg-white dark:bg-dark-500 hidden group-hover:block  left-10  rounded-tr-md rounded-br-md shadow-md">
          {item.items &&
            item.items.map((menuItem, menuItemIndex) =>
              menuItem?.itemHidden ? (
                ''
              ) : (
                <div key={menuItemIndex}>
                  <Link
                    to={menuItem.path}
                    className={`${
                      isActiveSubMenu(menuItem)
                        ? 'menu-item-selected'
                        : 'menu-item'
                    }`}
                  >
                    <div
                      className={`${
                        isActiveSubMenu(menuItem)
                          ? ' font-medium text-red-500 '
                          : ' text-gray-700 dark:text-dark-100'
                      } dark:hover:text-red-500 hover:text-red-500`}
                    >
                      {menuItem.title}
                    </div>
                  </Link>
                </div>
              ),
            )}
        </div>
      </div>
    )
  }

  return (
    <Disclosure
      defaultOpen={
        item.items &&
        item.items.filter(
          (menuItem) =>
            menuItem.path.split('/').slice(0, 3).join('/') ===
            location.pathname.split('/').slice(0, 3).join('/'),
        ).length > 0
          ? true
          : false
      }
    >
      {({ open }) => (
        <>
          <div onClick={() => setOpenDropdown(!openDropdown)}>
            <Disclosure.Button
              className={`menu-item flex ${
                item?.className ? item?.className : ''
              }`}
              style={{ width: sidebarExpanded ? '244px' : '40px' }}
              // static={false}
            >
              <div
                className={`flex items-center group ${
                  item?.items &&
                  item?.items?.filter((menuItem) => isActiveSubMenu(menuItem))
                    .length > 0
                    ? 'fill-current text-red-500'
                    : ''
                }`}
              >
                {item.icon}
                {sidebarExpanded && (
                  <div className="flex gap-2">
                    <div
                      className={`text-sm ${
                        item?.items &&
                        item?.items.filter((menuItem) =>
                          isActiveSubMenu(menuItem),
                        ).length > 0
                          ? 'text-red-500'
                          : 'text-gray-700 dark:text-dark-100 group-focus:text-red-500'
                      } group-hover:text-red-500`}
                    >
                      {item.title}
                    </div>
                    {item?.badge && <div className="">{item?.badge}</div>}
                  </div>
                )}
              </div>
              {sidebarExpanded && (
                <div className="block ml-auto">
                  {openDropdown ? (
                    <ChevronUp className="menu-icon fill-current dark:text-dark-200" />
                  ) : (
                    <ChevronDown className="menu-icon fill-current dark:text-dark-200" />
                  )}
                </div>
              )}
            </Disclosure.Button>
          </div>
          <Transition show={openDropdown}>
            <Disclosure.Panel className="text-sm text-gray-700 dark:text-dark-100">
              {item?.items?.map((menuItem, menuItemIndex) =>
                menuItem?.itemHidden ? (
                  ''
                ) : (
                  <div
                    key={menuItemIndex}
                    open={false}
                    onClick={() => dispatch(toggleNavigation())}
                  >
                    <Link
                      to={menuItem.path}
                      className={`${
                        isActiveSubMenu(menuItem)
                          ? 'menu-item-selected'
                          : 'menu-item'
                      }`}
                    >
                      <button>
                        <div
                          className={`${
                            isActiveSubMenu(menuItem)
                              ? 'dark:text-red-500  font-medium '
                              : 'group-hover:text-red-500 group-focus:text-red-500 dark:text-dark-100'
                          } ml-6`}
                        >
                          {menuItem.title}{' '}
                        </div>
                      </button>
                    </Link>
                  </div>
                ),
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

const DashboardSidebar = () => {
  const dispatch = useDispatch()
  const {
    sidebarExpanded,
    showNavigation,
    userData,
    questionReviewPage,
    userAccessArea,
    olpPath,
    platformStatus,
  } = useSelector((state) => ({
    platformStatus: state.dashboard.platformStatus,
    sidebarExpanded: state.dashboard.sidebarExpanded,
    showNavigation: state.dashboard.showNavigation,
    userData: state.personalInfo.userData,
    questionReviewPage: state.reviewQuestions.questionReviewPage,
    userAccessArea: state.dashboard.userAccessArea,
    olpPath: state.personalInfo.olpPath,
  }))

  const tabletView = window.matchMedia('(max-width: 768px)')
  const [matches, setMatches] = useState(tabletView.matches)
  const location = useLocation()
  const sidebarRef = useRef(null)

  const history = useHistory()
  // const [portrait, setPortrait] = useState(false)

  useEffect(() => {
    const handler = (e) => setMatches(e.matches)
    tabletView.addListener(handler)
    const checkIfClickedOutside = (e) => {
      if (
        showNavigation &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        dispatch(toggleNavigation())
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      tabletView.removeListener(handler)
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showNavigation])

  // touchscreen

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      function () {
        if (window.orientation !== 90 || window.orientation !== -90) {
          dispatch(openSidebar(true))
        }
      },
      false,
    )
  }, [dispatch])

  // sidebar always exapnd in mobile view
  useEffect(() => {
    if (matches) {
      dispatch(openSidebar(true))
    }
  }, [dispatch, matches])

  const isFreeTrial =
    platformStatus?.data?.show?.free_trial_badge &&
    platformStatus?.data?.show?.upgrade_link &&
    !platformStatus?.data?.show?.package_expiration

  const isExpired =
    platformStatus?.data?.show?.package_expiration &&
    platformStatus?.data?.days_left < 0

  const isExpireSoon = platformStatus?.data?.show?.expires_soon_tooltip

  const INTERVIEW_ACCESS_TYPES = [
    'interviews',
    'interviewsuk',
    'interviews-bond',
    'casper_webinar',
  ]

  const isActiveInterview =
    !userAccessArea?.data?.includes('current-student') &&
    INTERVIEW_ACCESS_TYPES.some((access) =>
      userAccessArea?.data?.includes(access),
    )

  const isActiveChatbot =
    userAccessArea?.data?.includes('chatbot') ||
    userData?.access_areas?.includes('chatbot')

  const isInterviewTutoring =
    userAccessArea?.data?.includes('interview-tutoring') ||
    userData?.access_areas?.includes('interview-tutoring')

  const navigation = {
    dashboard: [
      {
        items: [
          {
            title: 'Home',
            path: '/',
            icon: <Home className="menu-icon fill-current " />,
          },
        ],
      },
      {
        isHide:
          userAccessArea?.isLoading ||
          userAccessArea?.data?.includes('current-student'),
        items: [
          {
            title: 'Upgrade',
            path: '/profile/my-purchases',
            icon: <User className="menu-icon fill-current " />,
            isHide: userAccessArea?.isLoading || !isFreeTrial,
            textGreen: true,
          },
        ],
      },
      {
        title: olpPath === 'hpat' ? 'HPAT' : 'UCAT',
        items: [
          {
            title: 'Calendar',
            path: `${isUcatPath}/calendar`,
            icon: <Calendar className="menu-icon fill-current " />,
            checkPathLength: olpPath === 'hpat' ? null : 3,
          },
          {
            title: 'Chat with Mai',
            path: `${isUcatPath}/chatbot`,
            icon: <ChatIcon className="menu-icon fill-current " />,
            checkPathLength: 3,
            className: 'chat-with-mai',
          },
          {
            title: 'Live Teaching',
            type: 'dropdown',
            icon: <CalendarAdd className="menu-icon fill-current " />,
            className: 'live-teaching-dropdown tour-workshop-prompt',
            checkPathLength: 3,
            outsidePath: '/orientation/',
            items: [
              {
                title: `${
                  olpPath === 'hpat' ? `Dr. Ann's` : `Dr. Ray's`
                } Workshop`,
                path: '/teaching/workshop/bookings',
              },
              {
                title: 'Classes',
                path: '/teaching/classes/bookings',
              },
              {
                title: `${
                  olpPath === 'hpat' ? 'HPAT Tutoring' : 'UCAT Tutoring'
                }`,
                path: '/tutoring',
              },
              {
                title: 'Drop in Q&As',
                path: '/teaching/drop-in-q&as/bookings',
                itemHidden: olpPath === 'hpat',
              },
            ],
          },
          {
            title: 'Learn',
            path: `${isUcatPath}/learn`,
            icon: <Learn className="menu-icon fill-current " />,
            checkPathLength: olpPath === 'hpat' ? null : 3,
          },
          {
            title: 'QBank',
            type: 'dropdown',
            path:
              olpPath === 'hpat'
                ? '/qbank/practice/section'
                : `${isUcatPath}/qbank/practice/subtest`,
            icon: <Archieve className="menu-icon fill-current " />,
            checkPathLength: olpPath === 'hpat' ? 3 : null,
            items: [
              {
                title: 'Practice',
                path:
                  olpPath === 'hpat'
                    ? '/qbank/practice/section'
                    : `${isUcatPath}/qbank/practice/subtest`,
              },
              {
                title: olpPath === 'hpat' ? 'Section Mocks' : 'Subtest Mocks',
                path:
                  olpPath === 'hpat'
                    ? '/qbank/section-mocks'
                    : `${isUcatPath}/qbank/subtest-mocks`,
              },
              {
                title: 'Full Mocks',
                path: `${isUcatPath}/qbank/full-exam-mocks`,
              },
              {
                title: 'History',
                path: `${isUcatPath}/qbank/history`,
              },
            ],
          },
          {
            title: 'Analytics',
            type: 'dropdown',
            path: '/analytics',
            icon: <Graph className="menu-icon fill-current " />,
            checkPathLength: olpPath === 'hpat' ? 3 : 4,
            items: [
              {
                title: 'Performance',
                path: `${isUcatPath}/analytics/performance`,
              },
              {
                title: 'Errors',
                path: `${isUcatPath}/analytics/error-log`,
              },
            ],
          },
          {
            title: 'Skill Trainers',
            path: `${isUcatPath}/skill-trainers`,
            icon: <Math className="menu-icon fill-current " />,
            checkPathLength: olpPath === 'hpat' ? null : 3,
          },
        ],
      },
      {
        items: [
          {
            hidden: olpPath === 'hpat',
            title: 'Interviews',
            type: olpPath === 'hpat' ? '' : 'dropdown',
            path:
              olpPath === 'hpat'
                ? '/interviews/interview-guide'
                : '/interviews',
            checkPathLength: 3,
            icon: <Chat className="menu-icon fill-current " />,
            customActive: 'interviews',
            items: [
              {
                title: 'Resources',
                path: '/interviews/resources',
                includesUrl: [
                  '/interviews/resources',
                  '/interviews/interview-guide',
                  '/ucat/interviews/module',
                  '/interviews/university-specific-questions',
                  '/ucat/module/unispecificresources',
                  '/interviews/interview-bank',
                  '/ucat/interviews',
                ],
              },
              {
                title: 'Live Events',
                path: '/interviews/live-events',
                includesUrl: [
                  '/interviews/live-events',
                  '/interviews/events',
                  '/interviews/mock-interviews',
                  '/interviews/casper',
                  '/interviews/interview-tutoring',
                  '/interviews/personalised-interviews',
                ],
                checkFullPath: true,
              },
            ],
            badge: isActiveInterview ? (
              <div className="px-1 py-0.5 text-center text-white text-xs font-semibold mr-4 bg-green-400 rounded">
                Active
              </div>
            ) : (
              ''
            ),
          },
          {
            title: 'University Admissions',
            type: 'dropdown',
            icon: <University className="menu-icon fill-current " />,
            checkPathLength: 6,
            items:
              olpPath === 'hpat'
                ? [
                    {
                      title: 'Leaving Cert Point Calculator',
                      path: '/university-admission/leaving-cert-point-calculator',
                    },
                    {
                      title: 'Medicine in Ireland',
                      path: '/university-admission/introduction/uniadmissions/book/entry-into-medicine-in-ireland/1',
                    },
                    {
                      title: 'Medical Courses Overview',
                      path: '/university-admission/introduction/uniadmissions/book/overview-of-medical-courses/1',
                    },
                    {
                      title: 'Contacts of Universities',
                      path: '/university-admission/introduction/uniadmissions/book/contact-details-of-universities/1',
                    },
                    {
                      title: "Options if You Don't Get In",
                      path: '/university-admission/introduction/uniadmissions/book/options-if-you-dont-get-in/1',
                    },
                  ]
                : [
                    {
                      title: 'Introduction',
                      path: `/university-admission/introduction/uniadmissions/book/${
                        userData?.cohort === 'UK'
                          ? 'university-admissions-introduction-uk'
                          : 'university-admissions-introduction'
                      }/1`,
                    },
                    {
                      title: 'Admissions Guide',
                      path: '/university-admission/new-guide',
                    },
                    {
                      title:
                        userData?.cohort === 'UK'
                          ? 'Personal Statement Guide'
                          : 'Written Application Guide',
                      path: `/university-admission/written/uniadmissions/book/${
                        userData?.cohort === 'UK'
                          ? 'personal-statement-guide'
                          : 'written-application-guide'
                      }/1`,
                    },
                    {
                      title: 'Virtual Work Experience',
                      path: '/university-admission/work-experience/booking',
                      itemHidden:
                        userData?.cohort === 'UK' || userData?.cohort === 'IE',
                    },
                    {
                      title: 'Extra Experiences',
                      path: '/university-admission/extra-experiences',
                    },
                  ],
          },
          {
            title: 'Community',
            path: '/community',
            icon: <Community className="menu-icon fill-current " />,
          },
          // {
          //   title: 'FAQ',
          //   path: '/faq',
          //   icon: <Faq className="menu-icon fill-current " />,
          // },
        ],
      },
    ],
  }
  const user = JSON.parse(localStorage.getItem('user-data'))
  return (
    <nav
      className={`${
        showNavigation ? 'mobile-show' : 'mobile-hidden'
      } sidebar dark:bg-dark-600 ${!matches && 'tour-sidebar'} ${
        !sidebarExpanded && 'sidebar-closed'
      } `}
    >
      <div
        className={`${
          showNavigation ? 'block md:hidden  ' : 'hidden'
        } fixed top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-40  w-screen h-full z-50 md:z-0`}
      />
      <div
        onClick={() => {
          if (questionReviewPage) {
            dispatch(toggleReviewSidebar())
          } else {
            dispatch(toggleSidebar())
          }
        }}
        className="collapse-icon "
      >
        {sidebarExpanded ? (
          <ChevronLeft className="h-6 w-6 fill-current  dark:text-white" />
        ) : (
          <ChevronRight className="h-6 w-6 fill-current dark:text-white" />
        )}
      </div>
      <div
        className={`logo ${
          sidebarExpanded ? '' : 'logo-mini'
        } dark:bg-dark-600`}
      >
        <Link to="/">{sidebarExpanded ? <Logo /> : <SmallLogo />}</Link>
      </div>
      <div
        style={{ maxHeight: '94vh', minWidth: '48px' }}
        className="bg-white h-full dark:bg-dark-600 z-50 fixed overflow-y-auto custom-scroll"
        ref={sidebarRef}
      >
        {navigation.dashboard &&
          navigation.dashboard.map((group, groupIndex) => (
            <div
              key={groupIndex}
              className={`block ${
                group?.isHide ? 'border-t-0' : 'border-t'
              } dark:border-dark-500 text-sm`}
            >
              {sidebarExpanded && group.title && (
                <div className="flex justify-between gap-2 pl-6 pr-3 pt-4 pb-3 text-gray-500 dark:text-dark-100">
                  <div>
                    {`${group.title} ${
                      olpPath === 'hpat'
                        ? ''
                        : user?.cohort === 'UK'
                        ? 'UK'
                        : 'ANZ'
                    } `}
                  </div>
                  {isFreeTrial ? (
                    <Link
                      to={'/profile/my-purchases'}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        history.push('/profile/my-purchases')
                        dispatch(toggleNavigation())
                      }}
                      className="px-2 py-0.5 text-center text-red-500 text-xs font-semibold mr-4 bg-red-200 rounded cursor-pointer"
                    >
                      Free Trial
                    </Link>
                  ) : isExpireSoon ? (
                    <Tooltip
                      text={platformStatus?.data?.expires_soon_tooltip_message}
                      side="right"
                    >
                      <Link
                        to={'/profile/my-purchases'}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          history.push('/profile/my-purchases')
                          dispatch(toggleNavigation())
                        }}
                        className="px-2 py-0.5 text-center text-orange-700 text-xs font-semibold mr-4 bg-orange-300 rounded cursor-pointer"
                      >
                        {platformStatus?.data?.package_status}
                      </Link>
                    </Tooltip>
                  ) : isExpired ? (
                    <Link
                      to={'/profile/my-purchases'}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        history.push('/profile/my-purchases')
                        dispatch(toggleNavigation())
                      }}
                      className="px-2 py-0.5 text-center text-red-500 text-xs font-semibold mr-4 bg-red-200 rounded cursor-pointer"
                    >
                      {platformStatus?.data?.package_status}
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              )}
              {group.items &&
                group.items.map((item, itemIndex) =>
                  item?.isHide ? (
                    ''
                  ) : item.type === 'dropdown' ? (
                    <DropdownMenu
                      key={itemIndex}
                      item={item}
                      sidebarExpanded={sidebarExpanded}
                    />
                  ) : item.hidden ? (
                    ''
                  ) : (
                    <Link
                      key={itemIndex}
                      to={item.path}
                      className={`${item?.className ? item?.className : ''} ${
                        isActive(
                          location.pathname,
                          item.path,
                          item.checkPathLength || 2,
                        )
                          ? 'menu-item-selected'
                          : 'menu-item'
                      }`}
                      onClick={() => dispatch(toggleNavigation())}
                    >
                      <Tooltip
                        className={sidebarExpanded ? 'hidden' : ''}
                        text={item?.title}
                        side="right"
                      >
                        <div
                          className={`w-full ${
                            !sidebarExpanded && item?.title === 'FAQ'
                              ? 'flex items-center'
                              : 'flex items-center'
                          } ${
                            item.textGreen
                              ? 'text-green-900 dark:text-green-900 group-hover:text-red-500'
                              : ''
                          }`}
                        >
                          <div>{item.icon}</div>
                          {sidebarExpanded && (
                            <div className="flex items-center justify-between w-full">
                              <div
                                className={`${
                                  isActive(
                                    location.pathname,
                                    item.path,
                                    item.checkPathLength || 2,
                                  )
                                    ? 'text-red-500 font-medium'
                                    : `${
                                        item.textGreen
                                          ? 'text-green-900 dark:text-green-900'
                                          : 'text-gray-700 dark:text-dark-100'
                                      }  group-hover:text-red-500 group-focus:text-red-500`
                                } `}
                              >
                                {item.title}
                              </div>
                              {item?.badge ? <span>{item?.badge}</span> : <></>}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    </Link>
                  ),
                )}
            </div>
          ))}
      </div>
    </nav>
  )
}

export default DashboardSidebar
