import { AppRouter } from 'utils/router'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import './styles/index.scss'
import store from 'redux/store'
import { useEffect } from 'react'

const App = () => {
  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification')
    } else {
      if (typeof Notification.requestPermission === 'function') {
        Notification.requestPermission().catch((err) =>
          console.log('Notification permission error:', err),
        )
      }
    }
  }, [])

  return (
    <Provider store={store}>
      <Toaster
        containerStyle={{
          inset: '0px',
        }}
        toastOptions={{
          duration: 500000,
          className: 'w-full',
          style: {
            maxWidth: '80vw',
            zIndex: 999999,
          },
        }}
      ></Toaster>
      <AppRouter />
    </Provider>
  )
}

export default App
