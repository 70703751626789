import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getFullExamMocksData = createAsyncThunk(
  'fullExamMocks/getSubtestMocksData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/exams`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const getScoreProgression = createAsyncThunk(
  'fullExamMocks/getScoreProgression',
  async ({ type, sectionId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/score-progression`, {
        headers: { notToRedirect404: true },
        params: { type, section_id: sectionId || undefined },
      })
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const fullExamMocksSlice = createSlice({
  name: 'fullExamMocks',
  initialState: {
    fullExamIndex: null,
    fullExamMocksData: { isLoading: false, data: [], isLocked: false },
    scoreProgressionData: { isLoading: false, data: null },
    subtestProgressionData: { isLoading: false, data: null },
    showProgressionModal: false,
    selectedPeriod: { id: 1, value: 'percent', name: '% Correct' },
    showAttemptsDropdown: false,
    selectedAttemptType: { id: 1, value: 'all', name: 'All Attempts' },
    selectedSectionIndex: 0,
  },

  extraReducers: {
    [getScoreProgression.pending]: (state, { meta }) => {
      if (meta.arg.isSubtestMock) {
        state.subtestProgressionData.isLoading = true
      } else {
        state.scoreProgressionData.isLoading = true
      }
    },
    [getScoreProgression.fulfilled]: (state, { payload, meta }) => {
      if (meta.arg.isSubtestMock) {
        state.subtestProgressionData.isLoading = false
        state.subtestProgressionData.data = payload?.data
      } else {
        state.scoreProgressionData.isLoading = false
        state.scoreProgressionData.data = payload?.data
      }
      state.showAttemptsDropdown = (payload?.data?.scores?.length || 0) > 10
      if ((payload?.data?.scores?.length || 0) > 10) {
        state.selectedAttemptType = {
          id: 2,
          value: 'last10',
          name: 'Last 10 Attempts',
        }
      }
    },
    [getScoreProgression.rejected]: (state) => {
      state.scoreProgressionData.isLoading = false
      state.subtestProgressionData.isLoading = false
    },
    [getFullExamMocksData.pending]: (state, action) => {
      state.fullExamMocksData = { isLoading: true }
    },
    [getFullExamMocksData.fulfilled]: (state, action) => {
      state.fullExamMocksData = { isLoading: false, data: action.payload.data }
      state.fullExamMocksData.isLocked = false
      state.fullExamIndex = action.payload.data.exams.map((item) => 0)
    },
    [getFullExamMocksData.rejected]: (state, { payload }) => {
      state.fullExamMocksData.isLoading = false
      if (payload?.status === 403) {
        state.fullExamMocksData.isLocked = true
      }
    },
  },
  reducers: {
    setFullExamMocksData: (state, { payload }) => {
      state.fullExamMocksData = payload
    },
    setSelectedSectionIndex: (state, { payload }) => {
      state.selectedSectionIndex = payload
    },
    setSelectedAttemptType: (state, { payload }) => {
      state.selectedAttemptType = payload
    },
    setSelectedPeriod: (state, { payload }) => {
      state.selectedPeriod = payload
    },
    setShowProgressionModal: (state, { payload }) => {
      state.showProgressionModal = payload
    },
    updateFullExamIndex: (state, { payload }) => {
      state.fullExamIndex[payload.index] = payload.number
    },
    changeTabFullExamQue: (state, { payload }) => {
      state.fullExamIndex = state.fullExamMocksData.data[
        payload
      ].subtest_mocks.map((item) => 0)
    },
  },
})

export const {
  setFullExamMocksData,
  setSelectedSectionIndex,
  setSelectedPeriod,
  updateFullExamIndex,
  changeTabFullExamQue,
  setShowProgressionModal,
  setSelectedAttemptType,
} = fullExamMocksSlice.actions

export default fullExamMocksSlice.reducer
