import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getUserAccess = createAsyncThunk(
  'chatbot/getUserAccess',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/chatbot/check-user-access', {})
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const markTermsAgreed = createAsyncThunk(
  'chatbot/markTermAgreed',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post('/chatbot/mark-terms-agreed', {})
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const getChatHistory = createAsyncThunk(
  'chatbot/getChatHistory',
  async ({ prior_to_id } = {}, { rejectWithValue }) => {
    try {
      const params = prior_to_id ? { params: { prior_to_id } } : {}
      const res = await axiosInterceptors.get('/chatbot/chat-history', params)
      return res
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const submitChatMessage = createAsyncThunk(
  'chatbot/submitChatMessage',
  async ({ message }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post('/chatbot/chat', { message })
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const submitFeedback = createAsyncThunk(
  'chatbot/submitFeedback',
  async ({ responseId, feedback, vote }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `chatbot/rate-response/${responseId}`,
        {
          feedback: feedback || undefined,
          vote,
        },
      )
      return res.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const clearChatHistory = createAsyncThunk(
  'chatbot/clearChatHistory',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`chatbot/clear-chat-history`)
      return res.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const chatbotExamPerformance = createAsyncThunk(
  'chatbot/chatbotExamPerformance',
  async ({ examId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/chatbot/intents/exam-performance/${examId}`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const chatbotPerformanceAnalytics = createAsyncThunk(
  'chatbot/chatbotPerformanceAnalytics',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/chatbot/intents/analytics-next-steps`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const getChatIntents = createAsyncThunk(
  'chatbot/getChatIntents',
  async ({ intentId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`/chatbot/intents/${intentId}`)
      return res
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const askQuestionAboutQuestion = createAsyncThunk(
  'chatbot/askQuestionAboutQuestion',
  async ({ questionId, question_asked }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/history/question-chat/ask-message/${questionId}`,
        {
          question_asked: question_asked,
        },
        { headers: { notToRedirect404: true } },
      )
      return res
    } catch (err) {
      return rejectWithValue(err.response)
    }
  },
)

export const getQuestionChatHistory = createAsyncThunk(
  'chatbot/getQuestionChatHistory',
  async (questionId, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `/history/question-chat/messages/${questionId}`,
      )
      return res
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const rateResponse = createAsyncThunk(
  'chatbot/rateResponse',
  async (
    { responseId, vote, rating_reason, problem_area },
    { rejectWithValue },
  ) => {
    try {
      const res = await axiosInterceptors.post(
        `/history/question-chat/record-response-rating/${responseId}`,
        {
          rating: vote,
          rating_reason: rating_reason || undefined,
          problem_area,
        },
      )
      return res
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

const chatSlice = createSlice({
  name: 'chatbot',
  initialState: {
    checkUserAccess: { isLoading: false, data: null },
    markTermsAgreedLoader: false,
    chatData: { isLoading: false, data: null },
    submitChatMessageLoader: false,
    chatMessage: '',
    scrollUpLoader: false,
    feedBackLoading: false,
    feedback: { thumbsUpFeedBack: false, thumbsDownFeedBack: false },
    searchItem: '',
    clearChatHistoryLoader: false,
    intentsExamPerformance: {
      isLoading: false,
      data: null,
    },
    isPendingIntent: false,
    questionChatHistory: {
      isLoading: false,
      messages: [],
      warning: '',
    },
    ratingId: null,
    askMaiQuestionId: null,
    isDownVoted: false,
  },
  extraReducers: {
    [rateResponse.pending]: (state, { meta }) => {
      state.ratingId = meta.arg.responseId
    },
    [rateResponse.fulfilled]: (state, { payload }) => {
      state.ratingId = null
      const responseIndex = state.questionChatHistory.messages.findIndex(
        (message) => message.id === payload.data.qci.id,
      )

      if (responseIndex !== -1) {
        state.questionChatHistory.messages[responseIndex] = payload.data.qci
      }
    },
    [rateResponse.rejected]: (state) => {
      state.ratingId = null
    },

    [getQuestionChatHistory.pending]: (state) => {
      state.questionChatHistory.isLoading = true
    },
    [getQuestionChatHistory.fulfilled]: (state, { payload }) => {
      state.questionChatHistory.isLoading = false
      state.questionChatHistory.messages =
        [...payload.data.qcis].reverse() || []
    },
    [getQuestionChatHistory.rejected]: (state) => {
      state.questionChatHistory.isLoading = false
    },
    [askQuestionAboutQuestion.pending]: (state) => {
      state.askQuestionLoader = true
      state.submitChatMessageLoader = true
    },
    [askQuestionAboutQuestion.fulfilled]: (state, { payload }) => {
      state.askQuestionLoader = false
      state.submitChatMessageLoader = false
      state.questionChatHistory.messages.unshift(payload.data.qci)
      if (payload?.data?.warning) {
        state.questionChatHistory.warning = payload.data.warning
      }
      toast.success('Question submitted successfully', {
        duration: 3000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [askQuestionAboutQuestion.rejected]: (state, { payload }) => {
      if (payload?.status === 403 && payload?.data?.message) {
        state.questionChatHistory.warning = payload?.data?.message
      } else {
        if (state.questionChatHistory.warning) {
          state.questionChatHistory.warning = ''
        }
      }
      state.submitChatMessageLoader = false
      state.askQuestionLoader = false
    },
    [getChatIntents.pending]: (state) => {
      state.isPendingIntent = false
    },
    [getChatIntents.fulfilled]: (state, { payload }) => {
      if (payload?.data?.intent?.chat_prompt_history_item) {
        state.submitChatMessageLoader = false
        state.chatMessage = ''
        state.isPendingIntent = false
        state.chatData?.data?.chat_history.push(
          payload?.data?.intent?.chat_prompt_history_item,
        )
        if (state.chatData?.data?.pending_intent) {
          state.chatData.data.pending_intent = null
        }
      } else {
        state.isPendingIntent = true
      }
      if (payload?.data?.intent?.failed_at) {
        state.submitChatMessageLoader = false
        state.chatMessage = ''
        state.isPendingIntent = false
      }
    },
    [getChatIntents.rejected]: (state, { payload }) => {
      state.submitChatMessageLoader = false
      state.chatMessage = ''
      state.isPendingIntent = false
    },

    [chatbotPerformanceAnalytics.pending]: (state) => {
      state.intentsExamPerformance.isLoading = true
    },
    [chatbotPerformanceAnalytics.fulfilled]: (state, { payload }) => {
      state.intentsExamPerformance.isLoading = false
      state.intentsExamPerformance.data = payload.data
    },
    [chatbotPerformanceAnalytics.rejected]: (state) => {
      state.intentsExamPerformance.isLoading = false
    },

    [chatbotExamPerformance.pending]: (state) => {
      state.intentsExamPerformance.isLoading = true
    },
    [chatbotExamPerformance.fulfilled]: (state, { payload }) => {
      state.intentsExamPerformance.isLoading = false
      state.intentsExamPerformance.data = payload.data
    },
    [chatbotExamPerformance.rejected]: (state) => {
      state.intentsExamPerformance.isLoading = false
    },
    [clearChatHistory.pending]: (state) => {
      state.clearChatHistoryLoader = true
    },
    [clearChatHistory.fulfilled]: (state, { payload }) => {
      state.clearChatHistoryLoader = false
      toast.success('Chat history cleared successfully', {
        duration: 3000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [clearChatHistory.rejected]: (state) => {
      state.clearChatHistoryLoader = false
    },
    [submitFeedback.pending]: (state) => {
      state.feedBackLoading = true
    },
    [submitFeedback.fulfilled]: (state, { payload, meta }) => {
      state.feedBackLoading = false
      if (meta.arg?.vote === 1) {
        state.feedback.thumbsUpFeedBack = true
      } else {
        state.feedback.thumbsDownFeedBack = true
      }
    },
    [submitFeedback.rejected]: (state) => {
      state.feedBackLoading = false
    },
    [submitChatMessage.pending]: (state) => {
      state.submitChatMessageLoader = true
    },
    [submitChatMessage.fulfilled]: (state, { payload }) => {
      state.submitChatMessageLoader = false
      payload?.data?.item &&
        state.chatData?.data?.chat_history.push(payload?.data?.item)
      if (payload?.data?.warning) {
        state.chatData.data = {
          ...state.chatData.data,
          warning: payload?.data?.warning,
        }
      } else {
        if (state.chatData?.data?.warning) {
          state.chatData.data.warning = ''
        }
      }
    },
    [submitChatMessage.rejected]: (state, { payload }) => {
      state.submitChatMessageLoader = false
      if (payload?.status === 403 && payload?.data?.message) {
        state.chatData.data = {
          ...state.chatData.data,
          warning: payload?.data?.message,
        }
      } else {
        if (state.chatData?.data?.warning) {
          state.chatData.data.warning = ''
        }
      }
    },
    [getUserAccess.pending]: (state) => {
      state.checkUserAccess.isLoading = true
    },
    [getUserAccess.fulfilled]: (state, { payload }) => {
      state.checkUserAccess.data = payload.data
      state.checkUserAccess.isLoading = false
    },
    [getUserAccess.rejected]: (state) => {
      state.checkUserAccess.isLoading = false
    },
    [markTermsAgreed.pending]: (state) => {
      state.markTermsAgreedLoader = true
    },
    [markTermsAgreed.fulfilled]: (state, { payload }) => {
      state.markTermsAgreedLoader = false
      state.checkUserAccess.data = payload.data
    },
    [markTermsAgreed.rejected]: (state) => {
      state.markTermsAgreedLoader = false
    },
    [getChatHistory.pending]: (state, { meta }) => {
      if (meta.arg?.prior_to_id) {
        state.scrollUpLoader = true
      } else {
        state.chatData.isLoading = true
      }
    },
    [getChatHistory.fulfilled]: (state, { payload, meta }) => {
      state.scrollUpLoader = false
      state.chatData.isLoading = false
      if (
        state.chatData?.data?.chat_history?.length > 0 &&
        meta.arg?.prior_to_id
      ) {
        state.chatData.data.chat_history = [
          ...payload.data.chat_history,
          ...state.chatData.data.chat_history,
        ]
        state.chatData.data.more_exist_before = payload?.data?.more_exist_before
        state.chatData.data.intro_messages = payload?.data?.intro_messages
      } else {
        state.chatData.data = payload?.data
        if (!meta.arg?.prior_to_id && payload?.data?.pending_intent) {
          state.submitChatMessageLoader = true
          state.chatMessage = payload?.data?.pending_intent?.chat_message
          state.isPendingIntent = true
        }
      }
    },
    [getChatHistory.rejected]: (state) => {
      state.scrollUpLoader = false
      state.chatData.isLoading = false
    },
  },
  reducers: {
    setDownVoted: (state, { payload }) => {
      state.isDownVoted = payload
    },
    clearQuestionChatHistory: (state) => {
      state.questionChatHistory = {
        isLoading: false,
        messages: [],
        warning: '',
      }
    },
    setAskMaiQuestionId: (state, action) => {
      state.askMaiQuestionId = action.payload
    },
    clearFeedback: (state) => {
      state.feedback = { thumbsUpFeedBack: false, thumbsDownFeedBack: false }
    },
    clearChatData: (state) => {
      state.chatMessage = ''
      state.checkUserAccess = { isLoading: false, data: null }
      state.feedback = { thumbsUpFeedBack: false, thumbsDownFeedBack: false }
    },
    updateMessage: (state, action) => {
      state.chatMessage = action.payload
    },
    setSearchItem: (state, action) => {
      state.searchItem = action.payload
    },
  },
})

export const {
  setDownVoted,
  clearQuestionChatHistory,
  setAskMaiQuestionId,
  updateMessage,
  clearFeedback,
  setSearchItem,
  clearChatData,
} = chatSlice.actions
export default chatSlice.reducer
