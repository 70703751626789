import Bugsnag from '@bugsnag/js'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'
export const getAttempt = createAsyncThunk(
  'examPortal/getAttempt',
  async ({ attemptId, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `question-set-attempt/attempt/${attemptId}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (error) {
      onError && onError()
      Bugsnag.notify(error?.response || error)
      throw rejectWithValue(error.response)
    }
  },
)
export const syncProgress = createAsyncThunk(
  'examPortal/syncProgress',
  async (
    {
      questionAttempts,
      attemptId,
      onSuccess,
      isManual,
      pausedPeriodUpdates,
      pauseExam,
    },
    { rejectWithValue },
  ) => {
    const progress = questionAttempts
      .filter((data) => (isManual ? data : data.isUpdated))
      .map((questionAttempt) => {
        return {
          question_attempt_id: questionAttempt.id,
          serialised_input: questionAttempt.serialised_input || null,
          time_spent: questionAttempt.time_spent || 0,
          flagged: questionAttempt.flagged || false,
        }
      })
      ?.filter((data) => data?.question_attempt_id)

    const keysToFilter = [
      'pause_1_time',
      'pause_2_time',
      'pause_3_time',
      'overage_1_time',
      'overage_2_time',
      'overage_3_time',
    ]

    const pausedPeriodUpdatesFormatted = pausedPeriodUpdates.map((period) => {
      const filteredPeriod = { period_id: period.id }

      keysToFilter.forEach((key) => {
        if (period[key] !== null) {
          filteredPeriod[key] = period[key]
        }
      })

      return filteredPeriod
    })

    if ((progress && progress.length > 0) || pauseExam) {
      try {
        const res = await axiosInterceptors.put(
          `question-set-attempt/attempt/${attemptId}/progress`,
          {
            question_attempt_updates: progress,
            paused_period_updates: pausedPeriodUpdatesFormatted,
          },
        )
        onSuccess && onSuccess()
        return res
      } catch (error) {
        Bugsnag.notify(error?.response || error)
        throw rejectWithValue(error)
      }
    }
  },
)
export const setBookmark = createAsyncThunk(
  'examPortal/setBookmark',
  async (data) => {
    return await axiosInterceptors.put(`question/mark-as-bookmark/${data.id}`, {
      question_set_attempt_id: data.attemptId,
      bookmark: data.bookmark,
    })
  },
)
export const setTimingTrainer = createAsyncThunk(
  'examPortal/setTimingTrainer',
  async (data) => {
    return await axiosInterceptors.put(
      `/question-set-attempt/attempt/${data.attemptId}/mark-timing-trainer-used`,
    )
  },
)
export const reportStageReached = createAsyncThunk(
  'examPortal/reportStageReached',
  async (data) => {
    return await axiosInterceptors.put(
      `question-set-attempt/attempt/${data.attemptId}/record-stage-reached`,
      {
        identifier: data.identifier,
      },
    )
  },
)
export const autoSaveScratchpadText = createAsyncThunk(
  'examPortal/autoSaveScratchpadText',
  async (data) => {
    const repeatAutoSaveText = setInterval(async () => {
      await axiosInterceptors.put(
        `question-set-attempt/attempt/${data?.attempt?.id}/save-scratch-pad-content`,
        {
          stage_identifier: data?.stageIdentifier,
          content: data?.textareaRef?.current?.textContent || '',
        },
      )
    }, 10000)
    return repeatAutoSaveText
  },
)
export const finishAttempt = createAsyncThunk(
  'examPortal/finishAttempt',
  async ({ id, questionAttempts }, { rejectWithValue }) => {
    const progress = questionAttempts.map((questionAttempt) => {
      return {
        question_attempt_id: questionAttempt.id,
        serialised_input: questionAttempt.serialised_input || null,
        time_spent: questionAttempt.time_spent || 0,
        flagged: questionAttempt.flagged,
      }
    })
    try {
      const res = await axiosInterceptors.put(
        `/question-set-attempt/attempt/${id}/finish`,
        {
          question_attempt_updates: progress,
        },
      )
      return res
    } catch (error) {
      Bugsnag.notify(error?.response || error)
      throw rejectWithValue(error.response)
    }
  },
)
export const switchToUntimedMode = createAsyncThunk(
  'examPortal/switchToUntimedMode',
  async ({ id, simulatedConditions }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `/question-set-attempt/attempt/${id}/switch-simulated-modes`,
        {
          simulated_conditions: simulatedConditions,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)
export const saveRating = createAsyncThunk(
  'examPortal/saveRating',
  async (data) => {
    return await axiosInterceptors.put(
      `/question/save-rating/${data.questionId}`,
      {
        user_rating: data.rating,
      },
    )
  },
)
