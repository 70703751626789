import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

import { axiosInterceptors } from 'utils/router/api'

export const getMyQueries = createAsyncThunk(
  'tutor/getMyQueries',
  async ({ page, section_id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`online-tutoring/my-queries`, {
        params: {
          page,
          section_id,
        },
      })
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const getMyTutorQuestions = createAsyncThunk(
  'tutor/getMyTutorQuestions',
  async ({ type, filter, page, abortController }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`tutoring/discussions`, {
        signal: abortController?.signal,
        params: { tutoring_type: type, [filter]: filter ? 1 : undefined, page },
      })
      return res
    } catch (error) {
      throw rejectWithValue(error)
    }
  },
)

export const deleteTutorDiscussion = createAsyncThunk(
  'tutor/deleteTutorDiscussion',
  async ({ resourceId, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(
        `tutoring/discussion-request/${resourceId}`,
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const getMyTutorResource = createAsyncThunk(
  'tutor/getMyTutorResource',
  async ({ type, id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `tutoring/discussions/${type}/${id}`,
        {
          params: {},
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const submitTutorDiscussion = createAsyncThunk(
  'tutor/submitTutorDiscussion',
  async (data, { rejectWithValue }) => {
    const { type, question, resource_id, resource_type, onSuccess, onError } =
      data
    try {
      const res = await axiosInterceptors.post(`tutoring/discussion-request`, {
        type,
        question,
        resource_id,
        resource_type,
      })
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitResponseRating = createAsyncThunk(
  'tutor/submitResponseRating',
  async ({ rating, id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `online-tutoring/question-query-response/${id}/submit-response-rating`,
        {
          star_rating: rating,
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getPendingTutoring = createAsyncThunk(
  'tutor/getPendingTutoring',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `tutoring/pending-tutoring-access-approval`,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const approveTutoringAccess = createAsyncThunk(
  'tutor/approveTutoringAccess',
  async ({ id, approved, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `tutoring/approve-tutoring-access/${id}`,
        { approved },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

export const submitQueryResponse = createAsyncThunk(
  'tutor/submitQueryResponse',
  async (data, { rejectWithValue }) => {
    const { id, response, onSuccess, onError, attachment } = data
    try {
      const formData = new FormData()

      formData.append('response', response)
      attachment.length > 0 && formData.append('attachment', attachment[0])
      const res = await axiosInterceptors.post(
        `online-tutoring/question-query/${id}/submit-response`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      onSuccess && onSuccess(res)
      return res
    } catch (err) {
      const errorType = rejectWithValue(err?.response?.data)
      onError && onError(errorType?.payload)
      throw rejectWithValue(err.response.data)
    }
  },
)

const questionType =
  process.env.REACT_APP_OLP_TYPE === 'hpat' ? 'All Sections' : 'All Subtests'

const tutorSlice = createSlice({
  name: 'tutor',
  initialState: {
    contactReasonData: { isLoading: false, reasons: [] },
    selectedReason: {},
    toggleErrorReport: false,
    isSendLoading: false,
    successMessage: null,
    myTutorQuestions: { isLoading: false, data: null, pagination: null },
    tutorFilter: { name: 'All', key: '' },
    tutorPageNumber: 1,
    tutorPageLoader: false,
    myTutorResource: { isLoading: false, data: null, pagination: null },
    pendingTutorData: null,
    rejectTutorAccess: false,
    accessLoader: false,
    rejectLoader: false,
    approvalData: null,
    myQueries: { isLoading: false, data: null, pagination: null },
    myQueriesError: { error: null, locked: false },
    queriesPageLoader: false,
    queriesPageNumber: 1,
    selectedSection: {
      sectionName: questionType,
    },
    addResponseId: null,
    addResponseError: null,
    ratingId: null,
    tutorScenarioId: null,
    deleteDiscussionLoader: false,
    deleteDiscussionId: null,
  },

  extraReducers: {
    [submitResponseRating.pending]: (state, { meta }) => {
      state.ratingId = meta.arg.id
    },
    [submitResponseRating.fulfilled]: (state, { payload, meta }) => {
      state.ratingId = null
      const queryIndex = state.myQueries?.data?.queries?.findIndex(
        (data) => data?.id === payload?.data?.response?.question_query_id,
      )
      if (queryIndex !== -1) {
        const responseIndex = state.myQueries?.data?.queries[
          queryIndex
        ]?.responses.findIndex((data) => data?.id === meta.arg.id)
        if (responseIndex !== -1) {
          state.myQueries.data.queries[queryIndex].responses[responseIndex] =
            payload?.data?.response
        }
      }
    },
    [submitResponseRating.rejected]: (state, { payload }) => {
      state.ratingId = null
    },

    [submitQueryResponse.pending]: (state, { meta }) => {
      state.addResponseId = meta.arg.id
    },
    [submitQueryResponse.fulfilled]: (state, { payload, meta }) => {
      state.addResponseId = null

      const responseIndex = state.myQueries?.data?.queries?.findIndex(
        (data) => data?.id === payload?.data?.response?.question_query_id,
      )
      if (responseIndex >= 0) {
        state.myQueries.data.queries[responseIndex].responses = [
          ...state.myQueries?.data?.queries[responseIndex]?.responses,
          payload?.data?.response,
        ]
      }
    },
    [submitQueryResponse.rejected]: (state, { payload }) => {
      state.addResponseId = null
      state.addResponseError = payload
    },

    [getMyQueries.pending]: (state, { meta }) => {
      state.myQueriesError = { error: null, locked: false }
      if (meta.arg.page === 1) {
        state.myQueries.isLoading = true
      } else {
        state.queriesPageLoader = true
      }
    },
    [getMyQueries.fulfilled]: (state, { payload, meta }) => {
      state.myQueries.isLoading = false
      state.queriesPageLoader = false
      if (meta.arg.page === 1) {
        state.myQueries.data = payload.data
      } else {
        if (state.myQueries?.data?.queries?.length > 0)
          state.myQueries.data.queries = [
            ...state.myQueries?.data?.queries,
            ...payload?.data?.queries,
          ]
      }
      state.myQueries.pagination = payload.pagination
    },
    [getMyQueries.rejected]: (state, { payload }) => {
      state.myQueries.isLoading = false
      state.queriesPageLoader = false
      if (payload?.status === 403) {
        state.myQueriesError.locked = true
      }
      state.myQueriesError.error = payload?.data
    },
    [approveTutoringAccess.pending]: (state, { meta }) => {
      if (meta.arg.approved === 1) {
        state.accessLoader = true
      } else {
        state.rejectLoader = true
      }
    },
    [approveTutoringAccess.fulfilled]: (state, { payload, meta }) => {
      state.accessLoader = false
      state.rejectLoader = false
      state.approvalData = payload.data
    },
    [approveTutoringAccess.rejected]: (state) => {
      state.accessLoader = false
      state.rejectLoader = false
    },
    [getPendingTutoring.pending]: (state, { meta }) => {},
    [getPendingTutoring.fulfilled]: (state, { payload, meta }) => {
      state.rejectTutorAccess = true
      state.pendingTutorData = payload.data
    },
    [getPendingTutoring.rejected]: (state) => {
      state.rejectTutorAccess = false
    },
    [getMyTutorResource.pending]: (state, { meta }) => {
      state.myTutorResource.isLoading = true
    },
    [getMyTutorResource.fulfilled]: (state, { payload, meta }) => {
      state.myTutorResource.isLoading = false
      state.myTutorResource.data = payload.data
    },
    [getMyTutorResource.rejected]: (state) => {
      state.myTutorResource.isLoading = false
    },
    [getMyTutorQuestions.pending]: (state, { meta }) => {
      if (meta.arg.page === 1) {
        state.myTutorQuestions.isLoading = true
      } else {
        state.tutorPageLoader = true
      }
    },
    [getMyTutorQuestions.fulfilled]: (state, { payload, meta }) => {
      state.myTutorQuestions.isLoading = false
      state.tutorPageLoader = false
      if (meta.arg.page === 1) {
        state.myTutorQuestions.data = payload.data
      } else {
        if (state.myTutorQuestions?.data?.notes?.length > 0)
          state.myTutorQuestions.data.notes = [
            ...state.myTutorQuestions?.data?.notes,
            ...payload?.data?.notes,
          ]
      }

      state.myTutorQuestions.pagination = payload.pagination
    },
    [getMyTutorQuestions.rejected]: (state, { payload }) => {
      if (payload?.message !== 'canceled') {
        state.myTutorQuestions.isLoading = false
        state.tutorPageLoader = false
      }
    },
    [deleteTutorDiscussion.pending]: (state, { meta }) => {
      state.deleteDiscussionLoader = true
    },
    [deleteTutorDiscussion.fulfilled]: (state, action) => {
      state.deleteDiscussionLoader = false
      state.myTutorQuestions.data.notes =
        state.myTutorQuestions?.data?.notes?.filter(
          (resource) => resource?.id !== action?.meta?.arg?.resourceId,
        )
      state.deleteDiscussionId = null
    },
    [deleteTutorDiscussion.rejected]: (state) => {
      state.deleteDiscussionLoader = false
      state.setDeleteDiscussionId = null
    },
    [submitTutorDiscussion.pending]: (state) => {
      state.isSendLoading = true
    },
    [submitTutorDiscussion.fulfilled]: (state, { payload }) => {
      state.isSendLoading = false
      state.successMessage = payload?.data?.success_message || payload.message

      toast.success('Your message has been sent to the MedEntry team', {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [submitTutorDiscussion.rejected]: (state) => {
      state.isSendLoading = false
    },
  },
  reducers: {
    setDeleteDiscussionId: (state, { payload }) => {
      state.deleteDiscussionId = payload
    },
    setTutorScenarioId: (state, { payload }) => {
      state.tutorScenarioId = payload
    },
    clearResponseError: (state, { payload }) => {
      state.addResponseError = null
    },
    setSelectedSection: (state, { payload }) => {
      state.queriesPageNumber = 1
      if (payload) {
        state.selectedSection = payload
      } else {
        state.selectedSection = {
          sectionName: questionType,
        }
      }
    },
    clearQueriesData: (state) => {
      state.myQueries = { isLoading: false, data: null, pagination: null }
      state.myQueriesError = { error: null, locked: false }
      state.queriesPageLoader = false
      state.queriesPageNumber = 1
    },
    setRejectTutorAccess: (state, { payload }) => {
      state.rejectTutorAccess = payload
    },
    clearTutorData: (state, { payload }) => {
      state.myTutorQuestions = {
        isLoading: false,
        data: null,
        pagination: null,
      }
      state.tutorFilter = { name: 'All', key: '' }
      state.tutorPageNumber = 1
      state.tutorPageLoader = false
    },
    setTutorPageNumber: (state, { payload }) => {
      state.tutorPageNumber = payload
    },
    setQueriesPageNumber: (state, { payload }) => {
      state.queriesPageNumber = payload
    },
    setTutorFilter: (state, { payload }) => {
      state.tutorFilter = payload
    },
    setSelectedReason: (state, { payload }) => {
      state.selectedReason = payload
    },
    setToggleErrorReport: (state, { payload }) => {
      state.toggleErrorReport = payload
    },
    clearData: (state) => {
      state.contactReasonData = { isLoading: false, reasons: [] }
      state.selectedReason = {}
      state.successMessage = null
    },
    clearSuccessMessage: (state) => {
      state.successMessage = null
    },
    clearAccessData: (state) => {
      state.successMessage = null
      state.pendingTutorData = null
      state.accessLoader = false
      state.approvalData = null
    },
  },
})

export const {
  setDeleteDiscussionId,
  setTutorScenarioId,
  clearResponseError,
  setSelectedSection,
  clearQueriesData,
  setRejectTutorAccess,
  clearAccessData,
  clearTutorData,
  setTutorPageNumber,
  setQueriesPageNumber,
  setTutorFilter,
  setSelectedReason,
  setToggleErrorReport,
  clearData,
  clearSuccessMessage,
} = tutorSlice.actions

export default tutorSlice.reducer
