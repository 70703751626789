import VideoSlider from 'components/booking/VideoSlider'
import { Loading } from 'components/icons'
import PlayingIcon from 'components/icons/PlayingIcon'
import { getMediaArray, parseVTT } from 'components/shared/helper'
import React, { useRef, useState, useCallback, useEffect } from 'react'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import {
  setChangedPollDuration,
  setSelectedPollVideoMedia,
  setVolume,
} from 'redux/learn/resourceSlice'
import screenfull from 'screenfull'

const PollExplanationVideo = ({ solutionFormattedVideo }) => {
  const dispatch = useDispatch()
  const { volume, selectedPollVideoMedia, changedPollDuration, autoQuality } =
    useSelector((state) => ({
      volume: state.resource.volume,
      selectedPollVideoMedia: state.resource.selectedPollVideoMedia,
      changedPollDuration: state.resource.changedPollDuration,
      autoQuality: state.resource.autoQuality,
    }))

  // State management
  const [videoState, setVideoState] = useState({
    transcriptArray: [],
    playedSeconds: 0,
    isFullScreen: false,
    duration: 0,
    isPlaying: false,
    isMuted: false,
    playbackSpeed: 1,
    currentSubtitleIndex: null,
    bufferLoading: false,
  })

  const [checkSpeed, setCheckSpeed] = useState(0)

  // Refs
  const playerRef = useRef()
  const videoRef = useRef()

  const checkQualityAvailable = (qualityNumber) => {
    const media = mediaArray
      .filter((data) => data.qualityNumber < qualityNumber)
      .sort((a, b) => b.qualityNumber - a.qualityNumber)
      .shift()

    return media || mediaArray[mediaArray?.length - 1] || mediaArray[0]
  }
  useEffect(() => {
    const check720P = checkQualityAvailable(7)
    if (check720P?.media && solutionFormattedVideo) {
      dispatch(setSelectedPollVideoMedia(check720P?.media))
    }
  }, [dispatch, solutionFormattedVideo])

  const handleQualityChange = (media) => {
    if (media?.id !== selectedPollVideoMedia?.id) {
      dispatch(setSelectedPollVideoMedia(media))
      dispatch(setChangedPollDuration(videoState?.playedSeconds))
    }
  }
  useEffect(() => {
    const checkInternetSpeed = async () => {
      try {
        const networkSpeed = parseFloat(checkSpeed)
        if (networkSpeed < 5) {
          const check360P = checkQualityAvailable(2)
          if (check360P?.media) {
            handleQualityChange(check360P?.media)
          }
        } else if (networkSpeed > 5 && networkSpeed < 25) {
          const check720P = checkQualityAvailable(4)
          if (check720P?.media) {
            handleQualityChange(check720P?.media)
          }
        } else if (networkSpeed > 25) {
          const check1440P = checkQualityAvailable(6)
          if (check1440P) {
            handleQualityChange(check1440P?.media)
          }
        }
      } catch (error) {
        console.error('Error checking internet speed', error)
      }
    }

    if (autoQuality && videoState?.isPlaying) {
      checkInternetSpeed()
    }
  }, [
    autoQuality,
    checkQualityAvailable,
    checkSpeed,
    handleQualityChange,
    videoState?.isPlaying,
  ])

  // Handlers
  const handlePlayPause = useCallback(() => {
    setVideoState((prev) => ({ ...prev, isPlaying: !videoState.isPlaying }))
  }, [videoState.isPlaying])

  const handleToggleVolume = useCallback(() => {
    setVideoState((prev) => ({ ...prev, isMuted: !prev.isMuted }))
  }, [])

  const handleVolumeChange = useCallback(
    (newVolume) => {
      dispatch(setVolume(newVolume))
    },
    [dispatch],
  )

  const handleToggleFullScreen = useCallback(() => {
    if (screenfull.isEnabled) {
      screenfull.toggle(videoRef.current)
      setVideoState((prev) => ({ ...prev, isFullScreen: !prev.isFullScreen }))
    }
  }, [])

  const handleProgress = useCallback(
    ({ playedSeconds }) => {
      if (changedPollDuration) {
        setVideoState((prev) => {
          return {
            ...prev,
            playedSeconds: changedPollDuration,
          }
        })
        playerRef.current.seekTo(parseInt(changedPollDuration))
        dispatch(setChangedPollDuration(0))
      } else {
        setVideoState((prev) => {
          const currentCueIndex = prev.transcriptArray.findIndex(
            (cue) => cue.start <= playedSeconds && cue.end >= playedSeconds,
          )
          return {
            ...prev,
            playedSeconds,
            currentSubtitleIndex: currentCueIndex,
          }
        })
      }
    },
    [changedPollDuration, dispatch],
  )

  const loadTranscriptData = useCallback(
    (duration) => {
      const subtitleArray = solutionFormattedVideo?.transcript?.vtt
        ? parseVTT(solutionFormattedVideo.transcript.vtt)
        : []

      setVideoState((prev) => ({
        ...prev,
        duration,
        transcriptArray: subtitleArray,
      }))
    },
    [solutionFormattedVideo?.transcript?.vtt],
  )

  const handleBuffer = useCallback(() => {
    setVideoState((prev) => ({ ...prev, bufferLoading: true }))
  }, [])

  const handleBufferEnd = useCallback(() => {
    setVideoState((prev) => ({ ...prev, bufferLoading: false }))
  }, [])

  const highlightClass = useCallback(
    ({ segment }) => {
      return videoState.playedSeconds >= parseFloat(segment?.start_time) &&
        videoState.playedSeconds <= parseFloat(segment?.end_time)
        ? 'bg-blue-500 bg-opacity-20'
        : ''
    },
    [videoState.playedSeconds],
  )

  const mediaArray = getMediaArray(solutionFormattedVideo)
  const transcriptData =
    solutionFormattedVideo?.transcript?.transcribed_paragraphs

  return (
    <div>
      {autoQuality && videoState?.isPlaying && (
        <ReactInternetSpeedMeter
          outputType="" // "alert"/"modal"/"empty"
          customClassName={null}
          pingInterval={5000} // milliseconds
          txtMainHeading="Opps..."
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          threshold={50}
          imageUrl="https://masterucat.medentry.edu.au/favicon.png"
          downloadSize="1561257" //bytes
          callbackFunctionOnNetworkTest={(data) => {
            setCheckSpeed(data)
          }}
        />
      )}
      {/* Video Player Section */}
      <div
        className="rounded-md relative mt-2 w-full"
        ref={videoRef}
        onClick={handlePlayPause}
      >
        {/* Subtitles Overlay */}
        {videoState.playedSeconds > 0 &&
          videoState.currentSubtitleIndex !== -1 && (
            <div
              className={`absolute bottom-11 left-0 w-full text-center font-semibold text-white ${
                videoState.isFullScreen
                  ? 'text-xl 3xl:text-4xl'
                  : 'text-base sm:text-lg md:text-base lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-3xl'
              }`}
            >
              <span className="bg-black bg-opacity-50 w-auto px-1">
                {
                  videoState.transcriptArray[videoState.currentSubtitleIndex]
                    ?.text
                }
              </span>
            </div>
          )}
        {/* Video Player */}
        <ReactPlayer
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          className="rounded"
          ref={playerRef}
          controls={false}
          onProgress={handleProgress}
          onDuration={loadTranscriptData}
          playbackRate={videoState.playbackSpeed}
          playing={videoState.isPlaying}
          onDoubleClick={handleToggleFullScreen}
          volume={volume}
          url={selectedPollVideoMedia?.url}
          height="100%"
          width="100%"
          muted={videoState.isMuted}
        />

        {/* Loading Indicator */}
        {videoState.bufferLoading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loading className="h-10 w-10 text-gray-300 bg-black opacity-25 rounded-full p-1" />
          </div>
        )}

        {/* Play Button Overlay */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {!videoState.isPlaying && (
            <div
              onClick={handlePlayPause}
              className="bg-red-500 h-16 w-16 rounded-full flex items-center justify-center cursor-pointer"
            >
              <PlayingIcon className="h-6 w-6 text-white ml-1" />
            </div>
          )}
        </div>

        {/* Video Controls */}
        <div className="w-full bg-gray-100">
          <VideoSlider
            playerRef={playerRef}
            isFullScreen={videoState.isFullScreen}
            duration={videoState.duration}
            handlePlayPause={handlePlayPause}
            isPlaying={videoState.isPlaying}
            volume={volume}
            handleToggleVolume={handleToggleVolume}
            handleVolumeChange={handleVolumeChange}
            handleToggleFullScreen={handleToggleFullScreen}
            playbackSpeed={videoState.playbackSpeed}
            subtitlesEnabled={false}
            setPlaybackSpeed={(speed) =>
              setVideoState((prev) => ({ ...prev, playbackSpeed: speed }))
            }
            mediaArray={mediaArray}
            isMuted={videoState.isMuted}
            playedSeconds={videoState.playedSeconds}
            updatePlayedSeconds={(seconds) =>
              setVideoState((prev) => ({ ...prev, playedSeconds: seconds }))
            }
            isFromPollExplanationVideo={true}
            pollPlayedSeconds={videoState.playedSeconds}
          />
        </div>
      </div>

      {/* Transcript Section */}
      <div className="py-4 dark:text-white disable-copy">
        {transcriptData?.length > 0 ? (
          transcriptData.map((paragraph, index) => (
            <div key={index}>
              <div className="py-2 cursor-pointer">
                {paragraph?.length > 0 &&
                  paragraph.map((segment, segmentIndex) => (
                    <span
                      key={segmentIndex}
                      className="relative dark:text-white"
                    >
                      <span className={highlightClass({ segment })}>
                        {segment.text}{' '}
                      </span>
                    </span>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <span>Transcript coming soon</span>
        )}
      </div>
    </div>
  )
}

export default PollExplanationVideo
