import Step from 'components/home/my-journey/Step'
import { FreeTrial, Spinner } from 'components/shared'
import { myJourneyData } from 'components/shared/helper'
import { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getJourneyData } from 'redux/dashboard/dashboardSlice'
import { setOpenMarkAsReadDialog } from 'redux/my-journey/myJourneySlice'

const MyJourney = ({ disabled }) => {
  const { userJourneyData } = useSelector((state) => ({
    userJourneyData: state.dashboard.userJourneyData,
  }))
  const dispatch = useDispatch()
  const getStepIcon = ({ slug }) => {
    const data = myJourneyData.find((data) => data.slug === slug)
    if (data && data?.icon) {
      return data.icon
    }
    return null
  }
  useEffect(() => {
    dispatch(getJourneyData())
  }, [dispatch])

  return (
    <>
      {userJourneyData && userJourneyData.isLocked ? (
        <FreeTrial />
      ) : userJourneyData.isLoading ? (
        <div className="py-3">
          <Spinner />
        </div>
      ) : (
        <div className="mt-9 flex flex-col-reverse overflow-x-auto overflow-y-hidden sm:flex-row sm:items-start md:items-start justify-between md:flex-wrap gap-16 h-40 custom-scroll">
          <div className="flex w-full items-center min-w-max md:items-start justify-between mb-14 md:mb-8 mt-0 md:mt-8">
            <div className="relative hidden md:block flex-auto w-12 mx-3 mt-4 border-t transition duration-500 ease-in-out border-gray-500 border-dashed">
              <span
                style={{
                  width: 0,
                  height: 0,
                  borderTop: '5px solid transparent',
                  borderBottom: '5px solid transparent',
                  borderLeft: '5px solid',
                  marginTop: '-5.5px',
                }}
                className="absolute -right-1 text-gray-500 dark:text-dark-400"
              ></span>
              <span
                style={{ marginTop: '-2.5px' }}
                className="absolute left-0 rounded-full bg-gray-500  dark:bg-dark-400 h-1 w-1"
              ></span>
            </div>
            {userJourneyData &&
              userJourneyData.data &&
              userJourneyData.data.next_entries &&
              [
                ...userJourneyData.data.recently_completed,
                ...userJourneyData.data.next_entries,
              ]
                .slice(0, 6)
                .map((myJourney, id) => (
                  <Fragment key={id}>
                    <div
                      key={id}
                      style={{ minHeight: '64px', minWidth: '64px' }}
                      className="hidden md:flex items-center justify-center relative h-16 w-16 rounded-full text-gray-900 dark:text-white dark:bg-opacity-10"
                    >
                      {disabled ? (
                        <Step
                          icon={getStepIcon({
                            slug: myJourney.activity.slug,
                          })}
                        />
                      ) : (
                        <Link
                          className={`${
                            myJourney?.activity?.manual ||
                            myJourney?.activity?.path_to
                              ? 'cursor-pointer'
                              : ''
                          }`}
                          to={
                            myJourney?.activity?.path_to
                              ? `/${myJourney.activity.path_to}`
                              : '#'
                          }
                          onClick={() => {
                            if (
                              myJourney &&
                              myJourney.activity &&
                              myJourney.activity.manual
                            ) {
                              dispatch(
                                setOpenMarkAsReadDialog({
                                  open: true,
                                  stepData: {
                                    name: myJourney?.activity?.name,
                                    slug: myJourney?.activity?.slug,
                                    html: myJourney?.activity?.manual_html,
                                    isCompleted: myJourney.finished_at
                                      ? true
                                      : false,
                                  },
                                }),
                              )
                            }
                          }}
                        >
                          <Step
                            icon={getStepIcon({
                              slug: myJourney.activity.slug,
                            })}
                            title={
                              myJourney.activity && myJourney.activity.name
                            }
                            titleBold
                            status={myJourney.finished_at ? 'completed' : ''}
                            tooltip={myJourney && myJourney.exclamation_message}
                            flagged={
                              (myJourney && myJourney.activity.flagged) || false
                            }
                            subTitle={
                              (myJourney && myJourney.caption_message) || null
                            }
                          />
                        </Link>
                      )}
                    </div>
                    <div className="relative hidden md:block flex-auto w-12 mx-3 mt-4 border-t transition duration-500 ease-in-out border-gray-500 border-dashed">
                      <span
                        style={{
                          width: 0,
                          height: 0,
                          borderTop: '5px solid transparent',
                          borderBottom: '5px solid transparent',
                          borderLeft: '5px solid',
                          marginTop: '-5.5px',
                        }}
                        className="absolute -right-1 text-gray-500 dark:text-dark-400"
                      ></span>
                      <span
                        style={{ marginTop: '-2.5px' }}
                        className="absolute left-0 rounded-full bg-gray-500 dark:bg-dark-400 h-1 w-1"
                      ></span>
                    </div>
                  </Fragment>
                ))}
            <div className="relative block md:hidden flex-auto w-12 mx-2.5 sm:mx-3 border-t transition duration-500 ease-in-out border-gray-500 border-dashed">
              <span
                style={{
                  width: 0,
                  height: 0,
                  borderTop: '5px solid transparent',
                  borderBottom: '5px solid transparent',
                  borderLeft: '5px solid',
                  marginTop: '-5.5px',
                }}
                className="absolute -right-1 text-gray-500 dark:text-dark-400"
              ></span>
              <span
                style={{ marginTop: '-2.5px' }}
                className="absolute left-0 rounded-full bg-gray-500 dark:bg-dark-400 h-1 w-1"
              ></span>
            </div>
            {userJourneyData &&
              userJourneyData.data &&
              userJourneyData.data.next_entries &&
              [
                ...userJourneyData.data.recently_completed,
                ...userJourneyData.data.next_entries,
              ]
                .slice(0, 4)
                .map((myJourney, id) => (
                  <Fragment key={id}>
                    <div
                      key={id}
                      style={{ minHeight: '64px', minWidth: '64px' }}
                      className="mt-3 hidden sm:block md:hidden relative h-16 w-16 dark:bg-opacity-10 rounded-full "
                    >
                      <Link
                        className={`${
                          myJourney?.activity?.manual ||
                          myJourney?.activity?.path_to
                            ? 'cursor-pointer'
                            : ''
                        }`}
                        to={
                          myJourney?.activity?.path_to
                            ? `/${myJourney.activity.path_to}`
                            : '#'
                        }
                        onClick={() => {
                          if (
                            myJourney &&
                            myJourney.activity &&
                            myJourney.activity.manual
                          ) {
                            dispatch(
                              setOpenMarkAsReadDialog({
                                open: true,
                                stepData: {
                                  name: myJourney?.activity?.name,
                                  slug: myJourney?.activity?.slug,
                                  html: myJourney?.activity?.manual_html,
                                  isCompleted: myJourney.finished_at
                                    ? true
                                    : false,
                                },
                              }),
                            )
                          }
                        }}
                      >
                        <Step
                          icon={
                            myJourneyData.find(
                              (data) => data.slug === myJourney.activity.slug,
                            )?.icon
                          }
                          title={myJourney.activity.name}
                          titleBold
                          status={myJourney.finished_at ? 'completed' : ''}
                          tooltip={myJourney && myJourney.exclamation_message}
                          flagged={
                            (myJourney && myJourney.activity.flagged) || false
                          }
                          subTitle={
                            (myJourney && myJourney.caption_message) || null
                          }
                        />
                      </Link>
                    </div>
                    <div className="relative hidden sm:block md:hidden flex-auto w-12 mx-2.5 sm:mx-3 border-t transition duration-500 ease-in-out border-gray-500 border-dashed">
                      <span
                        style={{
                          width: 0,
                          height: 0,
                          borderTop: '5px solid transparent',
                          borderBottom: '5px solid transparent',
                          borderLeft: '5px solid',
                          marginTop: '-5.5px',
                        }}
                        className="absolute -right-1 text-gray-500 dark:text-dark-400"
                      ></span>
                      <span
                        style={{ marginTop: '-2.5px' }}
                        className="absolute left-0 rounded-full bg-gray-500 dark:bg-dark-400 h-1 w-1"
                      ></span>
                    </div>
                  </Fragment>
                ))}
            {userJourneyData &&
              userJourneyData.data &&
              userJourneyData.data.next_entries &&
              [
                ...userJourneyData.data.recently_completed,
                ...userJourneyData.data.next_entries,
              ]
                .slice(0, 2)
                .map((myJourney, id) => (
                  <Fragment key={id}>
                    <div
                      key={id}
                      style={{ minHeight: '64px', minWidth: '64px' }}
                      className="mt-3 block sm:hidden relative h-16 w-16 dark:bg-opacity-10 rounded-full "
                    >
                      <Link
                        className={`${
                          myJourney?.activity?.manual ||
                          myJourney?.activity?.path_to
                            ? 'cursor-pointer'
                            : ''
                        }`}
                        to={
                          myJourney?.activity?.path_to
                            ? `/${myJourney.activity.path_to}`
                            : '#'
                        }
                        onClick={() => {
                          if (
                            myJourney &&
                            myJourney.activity &&
                            myJourney.activity.manual
                          ) {
                            dispatch(
                              setOpenMarkAsReadDialog({
                                open: true,
                                stepData: {
                                  name: myJourney?.activity?.name,
                                  slug: myJourney?.activity?.slug,
                                  html: myJourney?.activity?.manual_html,
                                  isCompleted: myJourney.finished_at
                                    ? true
                                    : false,
                                },
                              }),
                            )
                          }
                        }}
                      >
                        <Step
                          icon={
                            myJourneyData.find(
                              (data) => data.slug === myJourney.activity.slug,
                            )?.icon
                          }
                          title={myJourney.activity.name}
                          titleBold
                          status={myJourney.finished_at ? 'completed' : ''}
                          tooltip={myJourney && myJourney.exclamation_message}
                          flagged={
                            (myJourney && myJourney.activity.flagged) || false
                          }
                          subTitle={
                            (myJourney && myJourney.caption_message) || null
                          }
                        />
                      </Link>
                    </div>
                    <div className="relative block sm:hidden flex-auto w-12 mx-2.5 sm:mx-3 border-t transition duration-500 ease-in-out border-gray-500 border-dashed">
                      <span
                        style={{
                          width: 0,
                          height: 0,
                          borderTop: '5px solid transparent',
                          borderBottom: '5px solid transparent',
                          borderLeft: '5px solid',
                          marginTop: '-5.5px',
                        }}
                        className="absolute -right-1 text-gray-500 dark:text-dark-400"
                      ></span>
                      <span
                        style={{ marginTop: '-2.5px' }}
                        className="absolute left-0 rounded-full bg-gray-500 dark:bg-dark-400 h-1 w-1"
                      ></span>
                    </div>
                  </Fragment>
                ))}
          </div>
        </div>
      )}
    </>
  )
}
export default MyJourney
