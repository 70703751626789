import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getQbankFullExamData = createAsyncThunk(
  'qbankFullExam/getQbankFullExamData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`quiz/exams`)
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const saveVueMockScores = createAsyncThunk(
  'qbankFullExam/saveVueMockScores',
  async ({ vueId, scores }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `quiz/vue-mock-scores/${vueId}`,
        scores,
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  },
)

export const qbankFullExamSlice = createSlice({
  name: 'qbankFullExam',
  initialState: {
    qbankExamData: { isLoading: false, data: [] },
    videoData: null,
    videoModal: false,
    vueMockScoresSubmission: {
      isLoading: false,
      success: false,
      error: null,
    },
  },
  extraReducers: {
    [getQbankFullExamData.pending]: (state, action) => {
      state.qbankExamData.isLoading = true
    },
    [getQbankFullExamData.fulfilled]: (state, { payload }) => {
      state.qbankExamData = { isLoading: false, data: payload.data }
    },
    [getQbankFullExamData.rejected]: (state, action) => {
      state.qbankExamData.isLoading = false
    },
    [saveVueMockScores.pending]: (state) => {
      state.vueMockScoresSubmission = {
        isLoading: true,
        success: false,
        error: null,
      }
    },
    [saveVueMockScores.fulfilled]: (state, { payload, meta }) => {
      if (state?.qbankExamData?.data?.vue?.length > 0) {
        const vueIndex = state.qbankExamData.data.vue.findIndex(
          (mock) => mock.id === parseInt(meta.arg.vueId),
        )

        if (vueIndex !== -1) {
          state.qbankExamData.data.vue[vueIndex].user_scores =
            payload?.data?.scores || []
        }
      }

      state.vueMockScoresSubmission = {
        isLoading: false,
        success: true,
        error: null,
      }
    },
    [saveVueMockScores.rejected]: (state, { payload }) => {
      state.vueMockScoresSubmission = {
        isLoading: false,
        success: false,
        error: payload || 'Failed to save scores',
      }
    },
  },
  reducers: {
    setVideoData: (state, { payload }) => {
      state.videoData = payload
    },
    setVideoModal: (state, { payload }) => {
      state.videoModal = payload
    },
    resetVueMockScoresSubmission: (state) => {
      state.vueMockScoresSubmission = {
        isLoading: false,
        success: false,
        error: null,
      }
    },
  },
})

export const { setVideoData, setVideoModal } = qbankFullExamSlice.actions
export default qbankFullExamSlice.reducer
