import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { axiosInterceptors } from 'utils/router/api'
import {
  getAttempt,
  reportStageReached,
  syncProgress,
  finishAttempt,
  saveRating,
  setTimingTrainer,
  switchToUntimedMode,
} from './examPortalAction'

export const getQbankData = createAsyncThunk(
  'practice/getQbankData',
  async () => {
    return await axiosInterceptors.get('/qbank')
  },
)

export const startQbankAttempt = createAsyncThunk(
  'practice/startQbankAttempt',
  async (data) => {
    return await axiosInterceptors.post('/qbank/start-attempt', {
      section_number: data.sectionNumber,
      qbank_categories: data.qbankCategoriesIds,
      num_questions: data.numQuestions,
      timed: data.timed,
    })
  },
)

export const stageTimeSpent = createAsyncThunk(
  'examPortal/stageTimeSpent',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `/question-set-attempt/attempt/${data.attemptId}/record-stages-time-spent`,
        {
          stage_progressions: [
            {
              stage_identifier: data.identifier,
              time_spent: data.timeSpent,
              time_spent_reporting_issues:
                data.time_spent_reporting_issues || 0,
            },
          ],
        },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const deleteAttempt = createAsyncThunk(
  'examPortal/deleteAttempt',
  async ({ attemptId, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(`qbank/${attemptId}`)
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)
export const examPortalSlice = createSlice({
  name: 'examPortal',
  initialState: {
    attempt: null,
    stage: { type: 'loading' },
    stageSubdata: {},
    isDirty: false,
    modal: null,
    timingTrainerEnabled: false,
    viewTimingTrainer: false,
    countDown: { time_remaining: null },
    isDisableNextButton: [],
    bookmarkQuestion: [],
    questionRating: [],
    collapsedAccordions: [],
    optionClickable: true,
    hideCalc: true,
    viewRemainingTime: true,
    viewQuestionNumber: true,
    showCalculator: false,
    showScratchPad: false,
    disabledCalculator: true,
    reviewType: null,
    draggingAnswer: null,
    hoveringOverStatementId: null,
    hoveringOverAnswersBlock: false,
    draggingAwayFromStatement: null,
    optionsChosenTimedOnly: false,
    unSeenContentCount: 1,
    finishLoader: false,
    examPortalError: { data: null, isError: false, errorMassage: '' },
    showReviewScreen: false,
    isFlagChange: false,
    deleteAttemptLoader: false,
    showNavigator: false,
    serverError: false,
    serverErrorTimeOut: false,
    showPauseModal: false,
    pauseExam: false,
    currentPauseKey: null,
    pausedPeriodsArray: [],
    isPauseOverageTime: false,
    loadCalculator: false,
    initiallyLoadedCalculator: false,
  },
  extraReducers: {
    [deleteAttempt.pending]: (state, action) => {
      state.deleteAttemptLoader = true
    },
    [deleteAttempt.fulfilled]: (state, { payload }) => {
      state.deleteAttemptLoader = false
    },
    [deleteAttempt.rejected]: (state, action) => {
      state.deleteAttemptLoader = false
    },
    [switchToUntimedMode.pending]: (state, action) => {},
    [switchToUntimedMode.fulfilled]: (state, { payload }) => {
      state.attempt.simulated_conditions = payload?.data?.simulated_conditions
    },
    [switchToUntimedMode.rejected]: (state, action) => {
      state.finishLoader = false
    },
    [syncProgress.pending]: (state, { meta }) => {
      if (meta.arg?.isFinishedAttempt) {
        state.finishLoader = true
      }
    },
    [syncProgress.fulfilled]: (state, action) => {
      state.serverError = false
      state.serverErrorTimeOut = false
      const updatedQuestions =
        action.meta &&
        action.meta.arg &&
        action.meta.arg.questionAttempts
          ?.filter((data) => data.isUpdated)
          .map((data) => {
            return data.id
          })
      if (state?.stage?.stage?.question_attempts)
        state.stage.stage.question_attempts =
          state?.stage?.stage?.question_attempts?.map((data) => {
            if (updatedQuestions.includes(data.id)) {
              return { ...data, isUpdated: false }
            } else {
              return data
            }
          })
      if (state.isFlagChange) {
        state.isFlagChange = false
      }
    },
    [syncProgress.rejected]: (state, { payload }) => {
      if (state.finishLoader) {
        state.finishLoader = false
      }
      const isNetworkError = payload?.isAxiosError && !payload?.response
      const isStatus500Error = payload?.response?.status === 500
      if (isNetworkError || isStatus500Error) {
        state.serverError = true
        if (!state.firstServerErrorTime) {
          state.firstServerErrorTime = Date.now()
        }
      }
    },
    [setTimingTrainer.rejected]: (state, action) => {
      state.viewTimingTrainer = false
    },
    [setTimingTrainer.fulfilled]: (state, action) => {
      state.timingTrainerEnabled = true
    },
    [reportStageReached.pending]: (state, action) => {
      state.isLoading = true
    },
    [saveRating.fulfilled]: (state, action) => {
      if (action.payload.data && action.payload.data.solution_ratings) {
        state.questionRating = state.questionRating.map((itemRating) => {
          if (
            itemRating.id === action.payload.data.solution_ratings.question_id
          ) {
            return {
              ...itemRating,
              userRating: action.payload.data.solution_ratings.user_rating,
            }
          }
          return itemRating
        })
      }
    },
    [reportStageReached.fulfilled]: (state, action) => {
      state.isLoading = false
      state?.attempt?.stages.forEach((stage) => {
        state.stage.has_been_reached =
          action.payload.data &&
          action.payload.data[stage.identifier] &&
          action.payload.data[stage.identifier].has_been_reached
        state.stage.is_current =
          action.payload.data &&
          action.payload.data[stage.identifier] &&
          action.payload.data[stage.identifier].is_current
      })
    },
    [reportStageReached.rejected]: (state, action) => {
      state.isLoading = false
    },
    [finishAttempt.pending]: (state, action) => {
      state.finishLoader = true
      state.optionClickable = false
    },
    [finishAttempt.fulfilled]: (state, action) => {
      state.finishLoader = false
      state.optionClickable = true
    },
    [finishAttempt.rejected]: (state, action) => {
      state.finishLoader = false
      state.optionClickable = true
    },
    [getAttempt.pending]: (state, action) => {
      state.isLoading = true
    },
    [getAttempt.fulfilled]: (state, action) => {
      state.isLoading = false
      state.attempt = action.payload.data
      state.pausedPeriodsArray = action.payload?.data?.paused_periods
      state.timingTrainerEnabled = action?.payload?.data?.timing_trainer_used
      if (action?.payload?.data?.stages?.length === 0)
        state.examPortalError = {
          data: null,
          status: null,
          isError: true,
          errorMassage:
            'An error occurred with this attempt. Please try again, or contact MedEntry if the issue persists',
        }
      const currentStage =
        action?.payload?.data?.stages.find((stage) => stage.is_current) ||
        action?.payload?.data?.stages[0]
      state.countDown = {
        time_remaining: currentStage?.time_remaining,
      }
      if (currentStage?.stage?.type === 'questions') {
        state.isDisableNextButton =
          currentStage.stage.question_attempts?.map((item) => ({
            id: item.id,
            questionComplete: true, //to enable scroll -> questionComplete: item.question_completely_viewed,
          })) || []
        state.bookmarkQuestion = currentStage.stage.question_attempts?.map(
          (item) => ({
            id: item.question?.id,
            bookmark:
              item.question &&
              item.question.bookmarks &&
              item.question.bookmarks.length !== 0,
          }),
        )
        state.questionRating = currentStage.stage.question_attempts?.map(
          (item) => ({
            id: item.question.id,
            userRating:
              (item.question &&
                item.question.solution_ratings &&
                item?.question?.solution_ratings[0]?.user_rating) ||
              0,
          }),
        )
        //
        if (action.meta.arg.question_id) {
          const questionIndex = currentStage.stage.question_attempts.findIndex(
            (attempt) =>
              parseInt(action.meta.arg.question_id) === parseInt(attempt.id) ||
              parseInt(action.meta.arg.question_id) ===
                parseInt(attempt.question_id),
          )
          state.stage = currentStage
          state.stageSubdata = {
            ...state.stageSubdata,
            currentQuestionAttempt:
              currentStage.stage.question_attempts[
                questionIndex !== -1 ? questionIndex : 0
              ],
            isRecap: false,
          }
        } else {
          state.stage = currentStage
          state.stageSubdata = {
            ...state.stageSubdata,
            currentQuestionAttempt: currentStage.stage.question_attempts[0],
            isRecap: false,
          }
        }
      } else {
        state.stage = currentStage
      }
    },
    [getAttempt.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.showScratchPad = false
      state.examPortalError = {
        data: payload?.data?.data,
        status: payload?.status,
        isError: true,
        errorMassage: payload?.data?.message,
      }
    },
  },
  reducers: {
    setInitiallyLoadedCalculator: (state, { payload }) => {
      state.initiallyLoadedCalculator = payload
    },
    setLoadCalculator: (state, { payload }) => {
      state.loadCalculator = payload
    },
    setPauseOverageTime: (state, { payload }) => {
      state.isPauseOverageTime = payload
    },
    updateIsRecap: (state, { payload }) => {
      state.stageSubdata.isRecap = payload
    },
    clearExamError: (state, { payload }) => {
      state.examPortalError = { data: null, isError: false, errorMassage: '' }
    },
    clearExamPortal: (state, { payload }) => {
      state.examPortalError = { data: null, isError: false, errorMassage: '' }
      state.attempt = null
      state.stage = { type: 'loading' }
      state.stageSubdata = {}
      state.isDirty = false
      state.modal = null
      state.timingTrainerEnabled = false
      state.viewTimingTrainer = false
      state.countDown = { time_remaining: null }
      state.isDisableNextButton = []
      state.bookmarkQuestion = []
      state.questionRating = []
      state.collapsedAccordions = []
      state.optionClickable = true
      state.hideCalc = true
      state.viewRemainingTime = true
      state.viewQuestionNumber = true
      state.showCalculator = false
      state.showScratchPad = false
      state.reviewType = null
      state.draggingAnswer = null
      state.hoveringOverStatementId = null
      state.hoveringOverAnswersBlock = false
      state.draggingAwayFromStatement = null
      state.optionsChosenTimedOnly = false
      state.unSeenContentCount = 1
      state.answerOptionClickable = false
      state.examPortalError = { data: null, isError: false, errorMassage: '' }
      state.showNavigator = false
      state.showPauseModal = false
      state.pauseExam = false
      state.currentPauseKey = null
      state.pausedPeriodsArray = []
      state.isPauseOverageTime = false
    },
    updateUnSeenContentCount: (state, { payload }) => {
      state.unSeenContentCount += 1
    },
    updateTimeLimitWarningShown: (state, { payload }) => {
      state.stage.time_limit_warning_shown = payload
    },
    updateShowCalculator: (state, { payload }) => {
      state.showCalculator = payload
    },
    updateShowScratchPad: (state, { payload }) => {
      state.showScratchPad = payload
    },
    updateOptionClickable: (state, { payload }) => {
      state.optionClickable = payload || false
    },
    updateTimeSpent: (state) => {
      // state.stageSubdata.currentQuestionAttempt.time_spent =
      //   (state.stageSubdata.currentQuestionAttempt.time_spent || 0) + 1
    },
    updateReviewType: (state, { payload }) => {
      state.reviewType = payload.reviewType
    },
    updateStage: (state, { payload }) => {
      state.stage = payload.stage
      state.reviewType = null
    },
    updateStateOnNextClick: (state, { payload }) => {
      state.isDisableNextButton =
        payload.nextStage.stage.question_attempts?.map((item) => ({
          id: item.id,
          questionComplete: true, //to enable scroll -> questionComplete: item.question_completely_viewed,
        })) || []
      // state.bookmarkQuestion = payload.nextStage.stage.question_attempts?.map(
      //   (item) => {
      //     return {
      //       id: item.question?.id,
      //       bookmark:
      //         (item.question &&
      //           item.question.bookmarks &&
      //           item.question.bookmarks.length !== 0) ||
      //         false,
      //     }
      //   },
      // )
      // state.questionRating = payload.nextStage.stage.question_attempts?.map(
      //   (item) => ({
      //     id: item.question.id,
      //     userRating: item?.question?.solution_ratings[0]?.user_rating || 0,
      //   }),
      // )
    },
    updateStageSubData: (state, { payload }) => {
      state.stageSubdata = payload
    },
    updateCountDown: (state) => {
      if (!state.pauseExam || state.isPauseOverageTime) {
        state.stage.time_spent = state.stage?.time_spent + 1
        state.stage.time_remaining = state.stage?.time_remaining - 1
        state.countDown = {
          time_remaining: state.stage?.time_remaining,
        }
      }
      if (
        state.stageSubdata &&
        state.stageSubdata.currentQuestionAttempt &&
        state.stageSubdata.currentQuestionAttempt.id &&
        !state.pauseExam
      ) {
        const questionAttemptUpdateIndex = findIndex(
          state.stage.stage.question_attempts,
          { id: state?.stageSubdata?.currentQuestionAttempt?.id },
        )
        if (questionAttemptUpdateIndex !== -1) {
          state.stage.stage.question_attempts[questionAttemptUpdateIndex] = {
            ...state?.stage?.stage?.question_attempts[
              questionAttemptUpdateIndex
            ],
            time_spent:
              (state?.stage?.stage?.question_attempts[
                questionAttemptUpdateIndex
              ]?.time_spent || 0) + 1,
            isUpdated: true,
          }
        }
      }
      // if (
      //   state.attempt.simulated_conditions !== 'timerhidden' &&
      //   !state.stage.time_limit_warning_shown &&
      //   state.stage.time_remaining < 0
      // ) {
      //   state.stage.time_limit_warning_shown = true
      // }
    },
    updateTimeSpentReportingIssues: (state) => {
      state.stage.time_spent_reporting_issues =
        state.stage?.time_spent_reporting_issues + 1
    },
    handleAnswerInput: (state, { payload }) => {
      state.stageSubdata.currentQuestionAttempt = {
        ...state.stageSubdata.currentQuestionAttempt,
        serialised_input: payload.serialisedInput,
        is_awaiting_input: payload.isAwaitingInput,
      }
      const questionAttemptUpdateIndex = findIndex(
        state.stage.stage.question_attempts,
        {
          id: state.stageSubdata.currentQuestionAttempt.id,
        },
      )
      if (questionAttemptUpdateIndex >= 0) {
        state.stage.stage.question_attempts[questionAttemptUpdateIndex] = {
          ...state.stage.stage.question_attempts[questionAttemptUpdateIndex],
          serialised_input: payload.serialisedInput,
          is_awaiting_input: payload.isAwaitingInput,
          isUpdated: true,
        }
      }

      state.isDirty = true
    },
    updateFlaggedQuestion: (state, { payload }) => {
      state.stage.stage.question_attempts =
        state.stage.stage.question_attempts.map((attempt) => {
          if (payload.id === attempt.id) {
            return { ...attempt, flagged: !attempt.flagged, isUpdated: true }
          } else {
            return attempt
          }
        })
      if (
        state.stageSubdata &&
        state.stageSubdata.currentQuestionAttempt?.id === payload.id
      ) {
        state.stageSubdata.currentQuestionAttempt.flagged =
          !state.stageSubdata.currentQuestionAttempt.flagged
      }
      state.isFlagChange = true
    },
    setViewRemainingTime: (state) => {
      state.viewRemainingTime = !state.viewRemainingTime
    },
    setDisabledCalculator: (state, { payload }) => {
      state.disabledCalculator = payload
    },
    setViewQuestionNumber: (state) => {
      state.viewQuestionNumber = !state.viewQuestionNumber
    },
    setIsQuestionComplete: (state, { payload }) => {
      const newData = []
      for (let i = 0; i < state.isDisableNextButton.length; i++) {
        if (state.isDisableNextButton[i].id === payload) {
          newData.push({
            ...state.isDisableNextButton[i],
            questionComplete: true,
          })
          continue
        }
        newData.push({
          ...state.isDisableNextButton[i],
        })
      }
      state.isDisableNextButton = [...newData]
    },
    setQuestionRating: (state, { payload }) => {
      state.questionRating = state.questionRating.map((itemRating) => {
        if (itemRating.id === payload.id) {
          return {
            ...itemRating,
            userRating: payload.rating,
          }
        }
        return itemRating
      })
    },
    updateDraggingAnswer: (state, { payload }) => {
      state.draggingAnswer = payload
    },
    updateHoveringOverStatementId: (state, { payload }) => {
      state.hoveringOverStatementId = payload
    },
    updateHoveringOverAnswersBlock: (state, { payload }) => {
      state.hoveringOverAnswersBlock = payload
    },
    updateDraggingAwayFromStatement: (state, { payload }) => {
      state.draggingAwayFromStatement = payload
    },
    toggleOptionsChosenTimedState: (state) => {
      state.optionsChosenTimedOnly = !state.optionsChosenTimedOnly
    },
    updateQuestionAttempt: (state, { payload }) => {
      state.stage = payload.stage
      state.stageSubdata = {
        ...state.stageSubdata,
        currentQuestionAttempt: payload.questionAttempt,
        isRecap: false,
      }
    },
    setIsDirty: (state, { payload }) => {
      state.isDirty = payload
    },
    updateCollapsedAccrodian: (state, { payload }) => {
      state.collapsedAccordions = payload
    },
    updateBookmarkQuestionForReview: (state, { payload }) => {
      state.bookmarkQuestion = state.bookmarkQuestion.map((itemBookmark) => {
        if (itemBookmark.id === payload.id) {
          payload.bookmark = !itemBookmark.bookmark
          return {
            ...itemBookmark,
            bookmark: !itemBookmark.bookmark,
          }
        }
        return itemBookmark
      })
    },

    updateModal: (state, { payload }) => {
      state.modal = payload
    },
    toggleTimingTrainer: (state) => {
      state.viewTimingTrainer = !state.viewTimingTrainer
    },
    setShowReviewScreen: (state, { payload }) => {
      state.showReviewScreen = payload
    },
    updateAnswerOptionClickable: (state, { payload }) => {
      state.answerOptionClickable = payload
    },
    updateProceedToNext: (state) => {
      const nextStage = state.attempt?.stages?.find(
        (stageData) => stageData.id === state.stage.id,
      )
      state.stage = nextStage
      state.isDisableNextButton =
        nextStage.stage.question_attempts?.map((item) => ({
          id: item.id,
          questionComplete: true, //questionComplete: item.question_completely_viewed,
        })) || []
      // state.bookmarkQuestion = nextStage.stage.question_attempts?.map(
      //   (item) => {
      //     return {
      //       id: item.question?.id,
      //       bookmark: item.question.bookmarks.length !== 0,
      //     }
      //   },
      // )
      // state.questionRating = nextStage.stage.question_attempts?.map((item) => ({
      //   id: item.question.id,
      //   userRating: item?.question?.solution_ratings[0]?.user_rating || 0,
      // }))
    },
    setShowNavigator: (state, { payload }) => {
      state.showNavigator = payload
    },
    clearServerError: (state) => {
      state.serverError = false
      state.serverErrorTimeOut = false
    },
    setServerErrorTimeOut: (state, { payload }) => {
      state.serverErrorTimeOut = payload
    },
    setShowPauseModal: (state, { payload }) => {
      state.showPauseModal = payload
    },
    updatePauseExam: (state, { payload }) => {
      state.pauseExam = payload
    },
    setCurrentPauseKey: (state, { payload }) => {
      state.currentPauseKey = payload
    },
    updatePauseTime: (state) => {
      const currentStageId = state.stage?.stage?.identifier
      const currentStage = state.pausedPeriodsArray?.findIndex(
        (item) =>
          item.intro_stage_id === currentStageId ||
          item.questions_stage_id === currentStageId,
      )
      if (currentStage !== -1 && state.currentPauseKey) {
        const pauseTimeField = state.currentPauseKey
        if (state.pausedPeriodsArray[currentStage][pauseTimeField] === null) {
          state.pausedPeriodsArray[currentStage][pauseTimeField] = 1
        } else {
          if (state.pausedPeriodsArray[currentStage][pauseTimeField])
            state.pausedPeriodsArray[currentStage][pauseTimeField] += 1
        }
      }
    },
  },
})

export const {
  setPauseOverageTime,
  setCurrentPauseKey,
  updatePauseTime,
  updatePauseExam,
  setShowPauseModal,
  updateTimeSpentReportingIssues,
  setServerErrorTimeOut,
  clearServerError,
  updateIsRecap,
  setShowNavigator,
  clearExamError,
  clearExamPortal,
  updateUnSeenContentCount,
  updateCountDown,
  handleAnswerInput,
  setIsQuestionComplete,
  updateDraggingAnswer,
  updateHoveringOverStatementId,
  updateHoveringOverAnswersBlock,
  updateDraggingAwayFromStatement,
  setQuestionRating,
  updateReviewType,
  updateStageSubData,
  updateStage,
  toggleOptionsChosenTimedState,
  updateShowCalculator,
  updateShowScratchPad,
  updateOptionClickable,
  updateStateOnNextClick,
  updateQuestionAttempt,
  setIsDirty,
  setDisabledCalculator,
  setViewRemainingTime,
  setViewQuestionNumber,
  updateModal,
  updateCollapsedAccrodian,
  updateBookmarkQuestionForReview,
  updateFlaggedQuestion,
  handleFlaggingQuestionForReviewInQuestion,
  toggleTimingTrainer,
  updateProceedToNext,
  updateTimeLimitWarningShown,
  updateTimeSpent,
  updateAnswerOptionClickable,
  setShowReviewScreen,
  setLoadCalculator,
  setInitiallyLoadedCalculator,
} = examPortalSlice.actions

export default examPortalSlice.reducer
