import moment from 'moment'
import {
  Stars,
  Note,
  Video,
  Book,
  Eye,
  University,
  CommunityArrow,
  Math as MathIcon,
  Search,
  Weighing,
  Calculator,
  TickList,
  Register,
  Calendar,
  Chat,
  Menu,
  LogicalReasoning,
  Persons,
} from 'components/icons'
import React from 'react'

export const isHPAT = 'hpat'

export const OLP_TYPE_UCAT = 'ucat'
export const OLP_CATEGORY = {
  HPAT: 'HPAT',
  UCAT: 'UCAT',
}

export const resourceName =
  process.env.REACT_APP_OLP_TYPE === 'hpat' ? 'Book' : 'Lesson'

export const COHORTS = {
  ANZ: 'ANZ',
}

export const isUcatPath =
  process.env.REACT_APP_OLP_TYPE === 'hpat' ? '' : '/ucat'

export const modifiedAnchorTag = (Comp) => (props) => {
  const userData = JSON.parse(localStorage.getItem('user-data'))

  const otherBaseUrl = userData?.other_cohort_base_urls || []
  let url = props.href
  if (url) {
    let matchingString = ''
    const containsPart = otherBaseUrl.some((item) => {
      if (url.includes(item)) {
        matchingString = item
        return true
      }
      return false
    })

    if (containsPart && matchingString && userData?.cohort_base_url) {
      url = url?.replace(matchingString, userData?.cohort_base_url)
    }
  }
  return <Comp {...props} href={url} />
}

export const highlightSearchText = (text, searchText) => {
  const split = (string, search) => {
    let i = -1
    let indexes = []
    while (
      (i = string.toLowerCase().indexOf(search.toLowerCase(), i + 1)) !== -1
    ) {
      indexes.push(i)
    }
    let prev = 0
    let ind = []
    indexes.forEach((index) => {
      ind.push([string.slice(prev, index), 0])
      ind.push([string.slice(index, index + search.length), 1])
      prev = index + search.length
    })
    ind.push([string.slice(prev, string.length), 0])
    return ind
  }
  if (searchText) {
    const spt = split(text, searchText)
    return (
      <>
        {spt.map((string, index) =>
          string[1] ? (
            <span
              key={index}
              className="bg-yellow-400"
              style={{ height: 'fit-content' }}
            >
              {string[0]}
            </span>
          ) : (
            string[0]
          ),
        )}
      </>
    )
  }
  return text
}
export const getBandFromPr = (pr) => {
  if (pr >= 80) {
    return '1'
  } else if (pr >= 40 && pr < 80) {
    return '2'
  } else if (pr >= 10 && pr < 40) {
    return '3'
  } else if (pr < 10) {
    return '4'
  }
}
//use for countdown timer , convert seconds into min like this 130 sec to "02:10"
export const getTime = (seconds) => {
  const [m, s] = moment
    .utc(seconds * 1000)
    .format('mm:ss')
    .split(':')
    .map((item) => Number(item))
  let time = ''

  time += (m === 0 ? '00' : m < 10 ? '0' + m : m) + ':'
  time += s === 0 ? '00' : s < 10 ? '0' + s : s
  return time.split('').map((t, index) => {
    if (t === ':') {
      return (
        <span key={index} className="mx-0.5  dark:text-dark-100">
          {t}
        </span>
      )
    } else {
      return (
        <span
          key={index}
          className="px-1.5 py-0.5 bg-gray-100   dark:bg-dark-400 rounded-sm mx-0.5"
        >
          {t}
        </span>
      )
    }
  })
}

export const getTimeInMinutes = (seconds) => {
  const [m, s] = moment
    .utc(seconds * 1000)
    .format('mm:ss')
    .split(':')
    .map((item) => Number(item))
  let time = ''

  time += (m === 0 ? '00' : m < 10 ? '0' + m : m) + ':'
  time += s === 0 ? '00' : s < 10 ? '0' + s : s
  return time.split('').map((t, index) => {
    if (t === ':') {
      return (
        <span key={index} className="mx-0.5  dark:text-dark-100">
          {t}
        </span>
      )
    } else {
      return (
        <span key={index} className="">
          {t}
        </span>
      )
    }
  })
}

//use for show number like this '1st,2nd,3rd'
export const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}
// get hpat portal time
export const getHpatPortalTime = (seconds) => {
  const duration = moment.duration(seconds, 'seconds')
  const d = duration?.days()
  const h = duration?.hours()
  const m = duration?.minutes()
  const s = duration?.seconds()

  let time = []
  if (d) {
    time.push(d + (d >= 2 ? ' days' : ' day'))
  }
  if (h) {
    time.push(h + (h >= 2 ? ' hours' : ' hour'))
  }
  if (m) {
    time.push(m + (m >= 2 ? ' minutes' : ' minute'))
  }
  if (s) {
    time.push(s + (s >= 2 ? ' seconds' : ' second'))
  }

  return time?.join(', ')
}

// get time like this
export const getTimeWithHours = (seconds) => {
  const duration = moment.duration(seconds, 'seconds')
  const d = duration?.days()
  const h = duration?.hours()
  const m = duration?.minutes()
  const s = duration?.seconds()
  // const [h, m, s, d] = moment
  //   .utc(seconds * 1000)
  //   .format('dd:hh:mm:ss')
  //   .split(':')
  //   .map((item) => Number(item))
  let time = []
  if (d) {
    time.push(d + (d >= 2 ? ' days' : ' day'))
  }
  if (h) {
    time.push(h + (h >= 2 ? ' hours' : ' hour'))
  }
  if (m) {
    time.push(m + (m >= 2 ? ' mins' : ' min'))
  }
  if (s) {
    time.push(s + (s >= 2 ? ' seconds' : ' second'))
  }
  if (s === 0) {
    time.push(s + ' seconds')
  }

  return time?.join(', ')
}

export const getMinutesWithHours = (minutes) => {
  const min = minutes * 60
  const [h, m] = moment
    .utc(min * 1000)
    .format('HH:mm')
    .split(':')
    .map((item) => Number(item))
  let time = ''
  if (h) {
    if (h === 1) {
      time += h + `${m ? ' hour, ' : ' hour'}`
    } else {
      time += h + `${m ? ' hours, ' : ' hours'}`
    }
  }
  if (m) {
    if (m === 1) {
      time += m + ' min'
    } else {
      time += m + ' mins'
    }
  }
  return time
}

// get time remaining from seconds
export const timeRemainingFromNow = (seconds) => {
  const timeRemain = moment().add(seconds, 'seconds').format()

  return moment(timeRemain).fromNow(true)
}
// redirect to learn modules from skill trainers tips and tricks
export const getUrlOfLearn = (
  number,
  subModuleName,
  resourceSlug,
  learnData,
  moduleType,
  ordering,
) => {
  const moduleData =
    learnData && learnData.find((data) => data.number === number)
  const subModuleData =
    moduleData &&
    moduleData.submodules &&
    moduleData.submodules.find((subModule) => subModule.name === subModuleName)
  const resourceModuleData = moduleData?.resources?.find(
    (resource) => resource?.slug === resourceSlug,
  )
  const resourceData =
    subModuleData &&
    subModuleData.resources.find((resource) => resource.slug === resourceSlug)

  if (moduleType === 'resources') {
    if (moduleData && resourceModuleData) {
      return `${isUcatPath}/learn/${resourceModuleData?.slug}/${1}}`
    } else {
      return false
    }
  } else {
    if (moduleData && subModuleData && resourceData) {
      return `${isUcatPath}/learn/${resourceData.slug}/1`
    } else {
      return false
    }
  }
}

export const getLearnNewUrl = ({ slug, index }) => {
  return `${isUcatPath}/learn/${slug}/${index || '1'}`
}

//Arithmetic configuration
export const arithmetic = [
  { name: 'Select All', subName: '', operator: null },
  { name: 'Addition', subName: 'Your Slowest', operator: '+', key: 'addition' },
  {
    name: 'Subtraction',
    subName: 'Your Strongest',
    operator: '-',
    key: 'substraction',
  },
  {
    name: 'Multiplication',
    subName: 'Your Fastest',
    operator: '*',
    key: 'multiple',
  },
  {
    name: 'Percentage',
    subName: 'Your Weakest',
    operator: '%',
    key: 'percentage',
  },
  {
    name: 'Division',
    subName: 'Your Strongest',
    operator: '/',
    key: 'division',
  },
  { name: 'Ratio', subName: 'Your Strongest', operator: ':', key: 'ratio' },
]
export const rankColors = {
  '1st': 'yellow',
  '2nd': 'gray',
  '3rd': 'bronze',
}
export const badgeSmallColors = {
  profile: 'red',
  engagement: 'blue',
  community: 'orange',
}
export const badgesColorByArea = {
  Engagement: 'blue',
  Profile: 'red',
}

export const moduleCategoryName = {
  learn: 'Learn',
  interviews: 'Interviews',
  interviewsuk: 'Interviews - UK',
  uniadmissions: 'University Admissions',
  uniadmissionsuk: 'University Admissions - UK',
  unispecificresources: 'Uni-specific Resources',
  uncategorised: 'Uncategorised',
}

export const communityBadgesColors = {
  'valued-contributor': 'orange',
  'bronze-contributor': 'bronze',
  'silver-contributor': 'gray',
  'gold-contributor': 'yellow',
  'conversation-starter': 'orange',
  'top-rated': 'orange',
}
export const myJourneyData = [
  {
    slug: 'research-entry-requirements',
    icon: <University className="h-6 w-6 fill-current dark:text-white" />,
  },
  { slug: 'identify-gaps-in-cv', icon: <Stars className="h-6 w-6" /> },
  { slug: 'intro-video-with-dr-ray', icon: <Video className="h-6 w-6" /> },
  {
    slug: 'diagnostic-exam',
    icon: <Note className="h-6 w-6 fill-current  dark:text-white" />,
  },
  { slug: 'how-to-prepare-for-ucat', icon: <Book className="h-6 w-6" /> },
  {
    slug: 'essential-ucat-skills',
    icon: <Book className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'situational-judgement',
    icon: <Weighing className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'quantitative-reasoning',
    icon: <MathIcon className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'decision-making',
    icon: <CommunityArrow className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'verbal-reasoning',
    icon: <Book className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'mock-exam',
    icon: <Note className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'retry-review',
    icon: <Eye className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'identify-weaknesses',
    icon: <Search className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'train',
    icon: <Calculator className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'practice',
    icon: <TickList className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'subtests',
    icon: <Book className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'sections',
    icon: <Book className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'skill-trainers',
    icon: <Calculator className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'register-for-ucat',
    icon: <Register className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'lead-up-to-ucat-and-test-day',
    icon: <Book className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'sit-ucat',
    icon: <Calendar className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'check-that-important-experiences-obtained',
    icon: <Stars className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'apply-to-medicine-dentistry',
    icon: <Menu className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'prepare-for-interview',
    icon: <Chat className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'get-admitted-into-university',
    icon: <University className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'logical-reasoning-problem-solving',
    icon: <LogicalReasoning className="fill-current dark:text-white" />,
  },
  {
    slug: 'interpersonal-understanding',
    icon: <Persons className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'hpat-test-date',
    icon: <Calendar className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'apply-to-cao',
    icon: <Menu className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'lead-up-to-hpat-and-test-day',
    icon: <Book className="h-6 w-6 fill-current dark:text-white" />,
  },
  {
    slug: 'register-for-hpat',
    icon: <Register className="h-6 w-6 fill-current dark:text-white" />,
  },
  { slug: 'intro-video-with-dr-ann', icon: <Video className="h-6 w-6" /> },
  { slug: 'how-to-prepare-for-hpat', icon: <Book className="h-6 w-6" /> },
  {
    slug: 'essential-hpat-skills',
    icon: <Book className="h-6 w-6 fill-current dark:text-white" />,
  },
]
export const allowedVideoFormats = ['mpeg', 'mp4', 'ogg', 'webm']

// live event dates
export const isSameMonth = (date) => moment(date).startOf('month')
export const isSameYear = (date) => moment(date).startOf('year')

export const getRangeFromStartEndDateMiddleDate = ({
  startDate,
  middleDate,
  endDate,
}) => {
  if (middleDate) {
    const months = Math.abs(
      isSameMonth(startDate).diff(isSameMonth(middleDate), 'months'),
    )
    const years = Math.abs(
      isSameYear(startDate).diff(isSameYear(middleDate), 'years'),
    )

    if (years > 0) {
      return (
        moment(startDate).format('D MMMM YYYY, ') +
        moment(middleDate).format('D MMMM YYYY, ') +
        moment(endDate).format('D MMMM YYYY')
      )
    }

    if (months > 0) {
      return (
        moment(startDate).format('D MMMM, ') +
        moment(middleDate).format('D MMMM, ') +
        moment(endDate).format('D MMMM YYYY')
      )
    }
    if (startDate === middleDate) {
      return (
        moment(startDate).format(' D, ') + moment(endDate).format('D MMMM YYYY')
      )
    }
    return (
      moment(startDate).format(' D, ') +
      moment(middleDate).format('D, ') +
      moment(endDate).format('D MMMM YYYY')
    )
  } else {
    const months = Math.abs(
      isSameMonth(startDate).diff(isSameMonth(endDate), 'months'),
    )
    const years = Math.abs(
      isSameYear(startDate).diff(isSameYear(endDate), 'years'),
    )

    if (years > 0) {
      return (
        moment(startDate).format('D MMMM, YYYY, ') +
        moment(endDate).format('D MMMM, YYYY')
      )
    }

    if (months > 0) {
      return (
        moment(startDate).format('D MMMM, ') +
        moment(endDate).format('D MMMM , YYYY')
      )
    }

    return startDate === endDate
      ? moment(startDate).format('D MMMM, YYYY')
      : moment(startDate).format('D, ') + moment(endDate).format('D MMMM, YYYY')
  }
}

export const getActivityRedirectUrl = (data, olpPath) => {
  if (data.type === 'faq') {
    return '/faq'
  }
  if (data.type === 'quiz_attemptable') {
    const examData = data.location_breadcrumbs[0]
    const link =
      examData.data.type === 'exam'
        ? `${isUcatPath}/qbank/full-exam-mocks`
        : examData.data.type === 'subtestmock'
        ? olpPath === 'hpat'
          ? `/qbank/section-mocks`
          : `${isUcatPath}/qbank/subtest-mocks`
        : ''
    return link
  }
  if (data.type === 'module' || data.type === 'submodule') {
    const moduleData = data.location_breadcrumbs.find(
      (data) => data.type === 'module',
    )
    const link = `${isUcatPath}/${moduleData.data.area}/module/${moduleData.id}`
    return link
  }
  if (
    data.type === 'book_chapter' ||
    data.type === 'resource' ||
    data.type === 'transcript_position'
  ) {
    const moduleData = data?.location_breadcrumbs?.find(
      (data) => data?.type === 'module',
    )
    const resourceData = data?.location_breadcrumbs?.find(
      (data) => data?.type === 'resource',
    )
    const bookChapter = data?.location_breadcrumbs?.find(
      (data) => data?.type === 'book_chapter',
    )
    const transcriptPosition = data?.location_breadcrumbs?.find(
      (data) => data?.type === 'transcript_position',
    )

    const timeStamp = transcriptPosition
      ? transcriptPosition?.data?.transcript_start_time
      : ''

    const link = `${isUcatPath}/${moduleData?.data?.area}/${
      resourceData?.data?.slug
    }/${bookChapter?.data?.ordering || 1}${
      timeStamp ? `?timeStamp=${timeStamp}` : ''
    }`

    return link
  }
}

export const getTutorRedirectUrl = (bookmark, olpPath) => {
  const isHpat = olpPath === 'hpat' ? '' : '/ucat'

  const module = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'module',
  )
  const subModule = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'submodule',
  )
  const resource = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'resource',
  )
  const chapter = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'book_chapter',
  )
  const section = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'section',
  )
  const questionAttempt = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'question_attempt',
  )
  const subtestAttempt = bookmark.location_breadcrumbs.find(
    (data) => data.data.type === 'subtestmock',
  )

  const practiceAttempt = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'bank_attemptable',
  )

  const interviewBankScenario = bookmark.location_breadcrumbs.find(
    (data) => data.type === 'interview_bank_scenario',
  )

  const interviewLink = interviewBankScenario
    ? `/interviews/interview-bank?scenario_id=${interviewBankScenario?.id}`
    : `#`

  const link =
    module && resource
      ? `${isHpat}/${module?.data?.area}/${resource?.data?.slug}/${
          chapter?.data?.ordering || 1
        }?resourceType=tutor-question`
      : '#'

  const practiceQuestionLink =
    practiceAttempt &&
    `${isHpat}/qbank/history/exam-review/practice-exam/${practiceAttempt.data.section_id}/${questionAttempt.data.question_set_attempt_id}?question_attempt_id=${questionAttempt.id}`

  const reviewQuestionLink =
    questionAttempt &&
    section &&
    `${isHpat}/qbank/history/exam-review/full-exam/${section.id}/${questionAttempt.data.question_set_attempt_id}?question_attempt_id=${questionAttempt.id}`

  const subtestReviewQuestionLink =
    subtestAttempt &&
    `${isHpat}/qbank/history/exam-review/subtest-exam/${questionAttempt.data.question_section_id}/${questionAttempt.data.question_set_attempt_id}?question_attempt_id=${questionAttempt.id}`

  const continueAttempt =
    questionAttempt &&
    `/attempt/${questionAttempt?.data?.question_set_attempt_id}`

  if (bookmark?.resource_type === 'Question' && !questionAttempt) {
    return `/go-to-question/${bookmark.resource_id}`
  }

  if (
    bookmark?.resource_type === 'Question' &&
    !questionAttempt?.data?.is_finished
  ) {
    return continueAttempt
  }
  if (module && resource) {
    return link
  } else if (questionAttempt && section) {
    return reviewQuestionLink
  } else if (subtestAttempt) {
    return subtestReviewQuestionLink
  } else if (practiceAttempt) {
    return practiceQuestionLink
  } else if (interviewBankScenario) {
    return interviewLink
  } else {
    return '/'
  }
}

export const formatTimestamp = (timestamp) => {
  const hours = Math.floor(timestamp / 3600)
  const minutes = Math.floor((timestamp % 3600) / 60)
  const seconds = Math.floor(timestamp % 60)

  const formattedTime = `${hours ? hours.toString().padStart(2, '0') : ''}${
    hours ? ':' : ''
  }${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
  return formattedTime
}

export const noBookingWebsite = [
  {
    website: ['IE'],
    message: (
      <>Bookings for HPAT Weekly Grinds streams will open in early October.</>
    ),
  },
  {
    website: ['NZ'],
    message: (
      <>
        Bookings for UCAT Weekly Classes will open in early March. Bookings for
        Intensive Classes will open in early June.
      </>
    ),
  },
  {
    website: ['UK'],
    message: <>Bookings for UCAT Weekly Classes will open in early March.</>,
  },
  {
    website: ['AU'],
    message: (
      <>
        Bookings for UCAT Weekly Classes will open in early March. Bookings for
        Intensive Classes will open in early June.
      </>
    ),
  },
]

export const noRecordingWebsite = [
  {
    website: ['IE'],
    message: (
      <>Recordings for the Classes will be available once classes commence.</>
    ),
  },
  {
    website: ['NZ'],
    message: (
      <>Recordings for the Classes will be available once classes commence.</>
    ),
  },
  {
    website: ['UK'],
    message: (
      <>Recordings for the Classes will be available once classes commence.</>
    ),
  },
  {
    website: ['AU'],
    message: (
      <>Recordings for the Classes will be available once classes commence.</>
    ),
  },
]

// Determines if a given string is a valid CSS selector
export const isValidSelector = (selector) => {
  try {
    document.createDocumentFragment().querySelector(selector)
  } catch {
    return false
  }
  return true
}

// Question answer color mapping
export const getQuestionClassFromCorrectness = (correctNess) => {
  if (correctNess === 1) {
    return 'border-green-700 dark:bg-opacity-10 bg-green-300 dark:text-white'
  } else if (correctNess > 0 && correctNess < 1) {
    return 'border-orange-700 dark:bg-opacity-10 bg-orange-300 dark:text-white'
  } else {
    return 'border-red-500 dark:bg-opacity-10 bg-red-100 dark:text-white'
  }
}
export const getCurrentQuestionClass = (correctNess) => {
  if (correctNess === 1) {
    return 'bg-green-700  border-green-700 text-white dark:text-white'
  } else if (correctNess > 0 && correctNess < 1) {
    return 'bg-orange-700  border-orange-700 text-white dark:text-white'
  } else {
    return 'bg-red-500  border-red-500 text-white dark:text-white'
  }
}

export const getContactUrl = (website) => {
  const urlMap = {
    NZ: 'https://medentry.co.nz/contact-us',
    IE: 'https://medentry-hpat.ie/contact-us',
    AU: 'https://medentry.edu.au/contact-us',
    UK: 'https://medentry.co.uk/contact-us',
  }

  return urlMap[website] || '#'
}

export const parseTimestamp = (timestamp) => {
  if (!timestamp) return 0

  const timeComponents = timestamp.split(/[.:]/).map(parseFloat)

  if (timeComponents.length === 3) {
    const seconds =
      timeComponents[0] * 3600 + timeComponents[1] * 60 + timeComponents[2]
    return parseFloat(seconds.toFixed(2))
  }

  if (timeComponents.length === 4) {
    const seconds =
      timeComponents[0] * 3600 +
      timeComponents[1] * 60 +
      timeComponents[2] +
      timeComponents[3] / 1000
    return parseFloat(seconds.toFixed(2))
  }

  return 0
}

export const parseVTT = (vttText) => {
  if (!vttText) return []

  const lines = vttText.split('\n')
  const entries = []
  let currentEntry = null

  lines.forEach((line) => {
    const trimmedLine = line.trim()

    if (!trimmedLine || /^\d+$/.test(trimmedLine)) return

    if (trimmedLine.includes('-->')) {
      if (currentEntry) {
        currentEntry.text = currentEntry.text.join(' ')
        entries.push(currentEntry)
      }

      currentEntry = { start: 0, end: 0, text: [] }
      const [startTime, endTime] = trimmedLine.split(' --> ')
      currentEntry.start = parseTimestamp(startTime)
      currentEntry.end = parseTimestamp(endTime)
    } else if (currentEntry) {
      currentEntry.text.push(trimmedLine)
    }
  })

  if (currentEntry) {
    currentEntry.text = currentEntry.text.join(' ')
    entries.push(currentEntry)
  }

  return entries
}

export const defaultButtonClass = {
  '÷': 'calcRedBtn',
  '*': 'calcRedBtn',
  '+': 'calcRedBtn',
  '-': 'calcRedBtn',
  '%': 'calcRedBtn',
  '=': 'calcRedBtn',
  Backspace: 'calcRedBtn',
  c: 'calcRedBtn', // MRC button
  m: 'calcRedBtn', // memory subtract maybe
  p: 'calcRedBtn', // memory add
  x: 'calcRedBtn', // square root uses red as well
  _: 'calcRedBtn', // plus/minus toggle button
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: '',
  9: '',
}

// convert video media to array of objects
export const getMediaArray = (solutionFormattedVideo) =>
  [
    {
      quality: 'Original',
      media: solutionFormattedVideo?.original_media,
      qualityNumber: 7,
    },
    {
      quality: '2160p',
      media: solutionFormattedVideo?.quality_6_media,
      qualityNumber: 6,
      optionalQuality: '4K',
    },
    {
      quality: '1440p',
      media: solutionFormattedVideo?.quality_5_media,
      qualityNumber: 5,
      optionalQuality: 'HD',
    },
    {
      quality: '1080p',
      media: solutionFormattedVideo?.quality_4_media,
      qualityNumber: 4,
      optionalQuality: 'HD',
    },
    {
      quality: '720p',
      media: solutionFormattedVideo?.quality_3_media,
      qualityNumber: 3,
    },
    {
      quality: '480p',
      media: solutionFormattedVideo?.quality_2_media,
      qualityNumber: 2,
    },
    {
      quality: '360p',
      media: solutionFormattedVideo?.quality_1_media,
      qualityNumber: 1,
    },
  ].filter((item) => item.media !== null)

export const PROMPT_SELECTORS = {
  CHAT_WITH_MAI: '.chat-with-mai',
  LIVE_TEACHING_DROPDOWN: '.live-teaching-dropdown',
}

// function to check if a selector is valid
export const isValidPromptSelector = (selector) => {
  return Object.values(PROMPT_SELECTORS).includes(selector)
}
