import { Tab } from '@headlessui/react'
import { Notes, QuestionsAns } from 'components/shared'
import TutorQuestion from 'components/shared/chapter/module2/TutorQuestion'
import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  setBookingResourceTab,
  setIsClickedTab,
} from 'redux/learn/resourceSlice'
import VideoTranscript from './VideoTranscript'
import ReportIssue from 'components/shared/ReportIssue'

const NotesAndDiscussion = ({
  // tabData,
  // resourceTab,
  // setResourceTab,
  // tabIndex,
  tabId,
  playerRef,
  moduleName,
  bookName,
  chapterName,
}) => {
  const { search } = useLocation()
  const { resourceType } = useParams()
  const dispatch = useDispatch()
  const {
    resourceData,
    tabDataIndex,
    bookingResourceTab,
    userAccessArea,
    isClickedTab,
    bookDetails,
    resourceQuestionsData,
  } = useSelector((state) => ({
    isClickedTab: state.resource.isClickedTab,
    resourceData: state.resource.resourceData,
    tabDataIndex: state.resource.tabDataIndex,
    bookingResourceTab: state.resource.bookingResourceTab,
    userAccessArea: state.dashboard.userAccessArea,
    bookDetails: state.resource.bookDetails,
    resourceQuestionsData: state.resourceQuestions.resourceQuestionsData,
  }))

  const [openAskQuestion, setOpenAskQuestion] = useState(false)
  const [openReplyPage, setOpenReplyPage] = useState(false)

  const isVideoTypeChapter = bookDetails?.chapterable_type === 'video'

  const tutoringDiscussionAccessCheck = bookDetails?.discussable_with_tutor

  const tabData = [
    {
      title: 'Transcript',
      className: 'tour-transcript',
      key: 'transcript',
      hidden: !isVideoTypeChapter,
    },
    {
      title: 'Discussions',
      className: 'tour-qa',
      key: 'discussion',
    },
    {
      title: 'My Notes',
      className: 'tour-notes',
      key: 'notes',
    },
    {
      title: 'Add discussion note',
      className: 'tour-discuss-with-tutor',
      key: 'tutor-question',
      hidden: !tutoringDiscussionAccessCheck?.can_display_button,
    },
    {
      title: 'Report issue',
      key: 'report-issue',
    },
  ]

  const tab = new URLSearchParams(search)?.get('resourceType')
  const tabIndex = tabData?.findIndex((data) => data?.key === tab)

  const discussionCount =
    resourceQuestionsData?.pagination?.total ||
    bookDetails?.num_resource_questions
  return (
    <div className="px-6 mt-4">
      <Tab.Group
        manual
        onChange={(index) => {
          dispatch(setIsClickedTab(true))
          dispatch(setBookingResourceTab(index))
        }}
        defaultIndex={!isClickedTab ? tabIndex : null}
        selectedIndex={isClickedTab ? bookingResourceTab : null}
      >
        <Tab.List className="border-b border-gray-100 dark:border-dark-500">
          {tabData.map(
            (tab, tabIndex) =>
              !tab.hidden && (
                <Tab key={tabIndex} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`notes-discussion pb-2 mr-4 border-b border-transparent text-sm ${
                        selected
                          ? 'text-gray-900 dark:text-white border-red-500'
                          : 'text-gray-700 dark:text-dark-100'
                      } ${tab.className}`}
                    >
                      {tab.title}
                      {tab.key === 'discussion'
                        ? `${discussionCount ? ` (${discussionCount})` : ''}`
                        : ''}
                    </button>
                  )}
                </Tab>
              ),
          )}
        </Tab.List>
        <Tab.Panels className="w-full">
          {isVideoTypeChapter && (
            <Tab.Panel>
              <VideoTranscript playerRef={playerRef} />
            </Tab.Panel>
          )}
          <Tab.Panel>
            <QuestionsAns
              openAskQuestionPage={openAskQuestion}
              setOpenAskQuestion={setOpenAskQuestion}
              openReplyPage={openReplyPage}
              setOpenReplyPage={setOpenReplyPage}
              bookChapterId={tabId}
              questionTypeId="book_chapter_id"
              bookId={resourceData.data.book.id}
              resourceType="chapter"
              numResourceQuestions={discussionCount}
            />
          </Tab.Panel>
          <Tab.Panel>
            <div className="mb-20">
              <Notes
                // Deletenote={deleteNote}
                // setDeleteNote={setDeleteNote}
                noteType="chapter"
                noteSlug="book_chapter_id"
                noteId={tabId}
                bookId={resourceData.data.book.id}
                playerRef={playerRef}
                moduleName={moduleName}
                bookName={bookName}
                chapterName={chapterName}
              />
            </div>
          </Tab.Panel>
          {tutoringDiscussionAccessCheck?.can_display_button && (
            <Tab.Panel>
              <div className="mb-20">
                <TutorQuestion
                  openAskQuestionPage={openAskQuestion}
                  setOpenAskQuestion={setOpenAskQuestion}
                  openReplyPage={openReplyPage}
                  setOpenReplyPage={setOpenReplyPage}
                  noteType={resourceType}
                  noteSlug="book_chapter_id"
                  noteId={resourceData.data.book.id}
                  tutorType="book_chapter"
                  tutorSlug="book_chapter_id"
                  tutorId={tabId}
                />
              </div>
            </Tab.Panel>
          )}
          <Tab.Panel>
            <ReportIssue
              reasons={'learningmaterial'}
              selectedQuestionId={bookDetails?.id}
              firstCapital={true}
              currentQuestionId={bookDetails?.id}
              reportFromOutside
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default NotesAndDiscussion
